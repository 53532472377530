export const Corporate = "https://eventena.in/static/media/traditional5.bf79ec8e3ec809c130bd.jpg"

export const cards = [
    { 
        id: 1,
        image: "http://eventena.in/assets/images/workshopImg1.jpg",
        title: "Weddings",
        description: "We take care of every detail, from venue selection and décor to catering and entertainment, ensuring a stress-free and magical experience."
    },
    { 
        id: 2,
        image: Corporate ,
        title: "Corporate Events",
        description: "Whether it's a product launch, conference, or gala dinner, we deliver corporate events that are professional and impactful."
    },
    { 
        id: 3,
        image: "http://eventena.in/assets/images/workshopImg3.jpg",
        title: "Private Parties",
        description: "Celebrate life's special moments with our bespoke planning services for birthdays, anniversaries, and more."
    },
    { 
        id: 4,
        image: "http://eventena.in/assets/images/workshopImg4.jpg",
        title: "Themed Events",
        description: "From whimsical to sophisticated, our themed events are designed to captivate and entertain. We work closely with you to develop a concept that brings your vision to life."
    }
];
