import { styled } from "@mui/system";
import { Card, Avatar, Box, Typography } from "@mui/material";
import { url } from "inspector";
import Package_bg from "../../package/image/package-bg.avif"

export const StyledCard = styled(Card)(({ theme }) => ({
  width: "100%",
  borderRadius: "8px",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  backgroundImage: `url(${Package_bg})`
  // [theme.breakpoints.up("md")]: {
  //   backgroundImage: `url(${Package_bg})`,
  // },
  // backgroundRepeat: "no-repeat", 
}));

export const StyledAvatar = styled(Avatar)({
  // backgroundColor: '#63B3ED',
  backgroundImage: "linear-gradient(45deg, #874da2 0%, #c43a30 100%)",
  width: 64,
  height: 64,
  marginRight: "16px",
});

export const Header = styled(Box)({
  display: "flex",
  alignItems: "center",
  padding: "16px",
});

export const Title = styled(Typography)({
  margin: 0,
  color: "#70144f",
  fontSize: "20px",
  fontStyle: "oblique",
  fontWeight: "600",
  // fontFamily: "cursive",
});

export const Subtitle = styled(Typography)({
  margin: 0,
  color: "#f00",
  fontSize: "20px",
  fontStyle: "oblique",
  fontWeight: "600",
  // fontFamily: "cursive",
});

export const Description = styled(Typography)({
  marginBottom: "16px",
});

export const TagContainer = styled(Box)({
  marginBottom: "8px",
});

export const ActionContainer = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});

export const Container = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4),
  marginLeft: "0%",
  marginRight: "0%",
  paddingTop: "0px",
  [theme.breakpoints.up("md")]: {
    marginLeft: "5%",
    marginRight: "5%",
  },
}));

export const StyledImage = styled("img")({
  maxWidth: "100%",
  height: "250px",
  borderRadius: "8px",
  boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
});
