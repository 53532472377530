import React from "react";
import AboutUs from "../components/home/components/About"
import WorkshopSection from "../components/home/components/WorkshopSection";
import ScheduleSection from "../components/home/components/ScheduleSection";
import Screen5 from "../components/home/components/Screen5";
import Pricing from "../components/home/components/Pricing";
import WhyJoinUs from "../components/home/components/WhyJoinUs";
import TestimonialCarousel from "../components/home/components/TestimonialCarousel";
import Carousal from "../components/home/components/Carousal";
// import LampUI from "../components/home/components/LampUI";
import OtherService from "../components/home/components/OtherService";
import VortexUI from "../components/home/components/VortexUI";
import Header from "../components/reusable/Header/Header";
import SectionOne from "../components/home/components/SectionOne";
import SectionImagesTwo from "../components/home/components/SectionImagesTwo";
import Footer from "../components/reusable/Footer"
import Profile from "../components/home/components/profile";
const Home = () => {
    return (
        <div id="home">
            
            <Header/>
            <SectionOne/>
              <AboutUs/>
              {/* <LampUI/> */}
              <OtherService/>
            <WorkshopSection/>
             <Pricing/>
            <WhyJoinUs/>
            <SectionImagesTwo/>
            <TestimonialCarousel/>
            <VortexUI/>
            <Carousal/>
            <Profile />
           <Footer/>
        </div>
      
    )
}

export default Home;