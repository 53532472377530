import React from "react";
import Header from "../components/reusable/Header/Header";
import Footer from "../components/reusable/Footer";
import GalleryUI from "../components/gallery/components/Gallery";
const Gallery = () => {
    return (
        <div id="gallery">
            
            <Header/>
            <GalleryUI/>
           <Footer/>
        </div>
      
    )
}

export default Gallery;