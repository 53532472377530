import React from "react";
import Header from "../components/reusable/Header/Header";
import Footer from "../components/reusable/Footer";
import PackageList from "../components/package/components/PackageList";
const PackageComp = () => {
    return (
        <div id="package">
            <Header/>
            <PackageList />
           <Footer/>
        </div>
      
    )
}

export default PackageComp;