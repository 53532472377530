export const itemData = [
  {
    video: "https://www.youtube.com/embed/9FOdwebyz80?si=BmignrcaNdCjAo_p",
    title: "Video 1",
    cols: 1,
    rows: 1,
  },
  {
    video: "https://www.youtube.com/embed/P_8T7QXkIj4?si=0cyMyaAKLt34DlqR",
    title: "Video 2",
    cols: 1,
    rows: 1,
  },
  {
    video: "https://www.youtube.com/embed/rs9HdWrVe78",
    title: "Video 3",
    cols: 1,
    rows: 1,
  },
  {
    video: "https://www.youtube.com/embed/pub1ngwswPo",
    title: "Video 4",
    cols: 1,
    rows: 1,
  },
  {
    video: "https://www.youtube.com/embed/ROg9CWEceBA",
    title: "Video 5",
    cols: 1,
    rows: 1,
  },
  {
    video: "https://www.youtube.com/embed/BctFZgH-h5g",
    title: "Video 6",
    cols: 1,
    rows: 1,
  },
  {
    video: "https://www.youtube.com/embed/P7R4t0rvSLw",
    title: "Video 7",
    cols: 1,
    rows: 1,
  },
  {
    video: "https://www.youtube.com/embed/X5dFdyzl2FM",
    title: "Video 8",
    cols: 1,
    rows: 1,
  },
  {
    video: "https://www.youtube.com/embed/8NWmQ4De-hw",
    title: "Video 9",
    cols: 2,
    rows: 2,
  },
  {
    video: "https://www.youtube.com/embed/3z9ANsZudy4",
    title: "Video 10",
    cols: 1,
    rows: 1,
  },
  {
    video: "https://www.youtube.com/embed/llb0Xm7j5mA?si=X9OQCXSNX-4t0HwO",
    title: "Video 11",
    cols: 1,
    rows: 1,
  },
  {
    video: "https://www.youtube.com/embed/vPSkrB9JO9o?si=9uvb5K0mvqsZlRrs",
    title: "Video 12",
    cols: 2,
    rows: 2,
  },
  {
    video: "https://www.youtube.com/embed/zefBYW4JU0E?si=jRT3FzEs_WEFI47k",
    title: "Video 13",
    cols: 1,
    rows: 1,
  },
  {
    video: "https://www.youtube.com/embed/ryVALtbXdAQ?si=f9MwUUz8VphTfns6",
    title: "Video 14",
    cols: 2,
    rows: 2,
  },
  {
    video: "https://www.youtube.com/embed/oXslnCR8zqo?si=3Q9D4g_JGeH6no2k",
    title: "Video 15",
    cols: 1,
    rows: 2,
  },
  {
    video: "https://www.youtube.com/embed/h1jm2zoeA6Y?si=gKFQlYHQ5x1epN1J",
    title: "Video 16",
    cols: 1,
    rows: 2,
  },
  {
    video: "https://www.youtube.com/embed/IKOJHTF0R4M?si=qSe-7xGSN61cmEZi",
    title: "Video 17",
    cols: 2,
    rows: 2,
  },
  {
    video: "https://www.youtube.com/embed/B58WBUk2AVk?si=_u6Y7REZMPyNac6V",
    title: "Video 18",
    cols: 1,
    rows: 1,
  },
  {
    video: "https://www.youtube.com/embed/CyniWfDEt54",
    title: "Video 19",
    cols: 1,
    rows: 2,
  },
  {
    video: "https://www.youtube.com/embed/_znXycJXW_s",
    title: "Video 20",
    cols: 1,
    rows: 1,
  },
  {
    video: "https://www.youtube.com/embed/z4puXpkaKpM",
    title: "Video 21",
    cols: 1,
    rows: 1,
  },
  {
    video: "https://www.youtube.com/embed/Ku7V-o58VoU",
    title: "Video 22",
    cols: 2,
    rows: 2,
  },
  {
    video: "https://www.youtube.com/embed/MZvhvybhpdc",
    title: "Video 23",
    cols: 2,
    rows: 2,
  },
  {
    video: "https://www.youtube.com/embed/-Cc3zGBpo8E",
    title: "Video 24",
    cols: 1,
    rows: 1,
  },
  {
    video: "https://www.youtube.com/embed/9umt93zYZYI",
    title: "Video 25",
    cols: 1,
    rows: 1,
  },
  {
    video: "https://www.youtube.com/embed/f8gw2ZiRHvw",
    title: "Video 26",
    cols: 1,
    rows: 1,
  },
  {
    video: "https://www.youtube.com/embed/zefBYW4JU0E?si=Gxkg23_TRuoR7grF",
    title: "Video 27",
    cols: 1,
    rows: 1,
  },
  
  {
    video: "https://www.youtube.com/embed/vEhhf5JqA_s?si=tATRcA1SeZd2t6Z2",
    title: "Video 28",
    cols: 1,
    rows: 1,
  },
  {
    video: "https://www.youtube.com/embed/xkhSQDANfvo?si=j2lDawEx0pL18UyB",
    title: "Video 29",
    cols: 1,
    rows: 1,
  },
  {
    video: "https://www.youtube.com/embed/sZp2kWkUjsg",
    title: "Video 30",
    cols: 2,
    rows: 3,
  },
  {
    video: "https://www.youtube.com/embed/c_drecpDF54",
    title: "Video 31",
    cols: 1,
    rows: 1,
  },
  {
    video: "https://www.youtube.com/embed/96zHIkg1gL0",
    title: "Video 32",
    cols: 1,
    rows: 1,
  },
  {
    video: "https://www.youtube.com/embed/5jEHEtD5q-A",
    title: "Video 33",
    cols: 1,
    rows: 1,
  },
  {
    video: "https://www.youtube.com/embed/BQrPKnIJBTk",
    title: "Video 34",
    cols: 1,
    rows: 1,
  },
  {
    video: "https://www.youtube.com/embed/aOgka4T1mAU",
    title: "Video 35",
    cols: 1,
    rows: 1,
  },
  {
    video: "https://www.youtube.com/embed/vXLxbdRl1Uc",
    title: "Video 36",
    cols: 2,
    rows: 2,
  },
  {
    video: "https://www.youtube.com/embed/1-R_yK2UMwo",
    title: "Video 37",
    cols: 1,
    rows: 1,
  },
  {
    video: "https://www.youtube.com/embed/Fge6CuzOV4M",
    title: "Video 38",
    cols: 1,
    rows: 1,
  },
  {
    video: "https://www.youtube.com/embed/bLIIgRBAM6c",
    title: "Video 39",
    cols: 2,
    rows: 2,
  },
  {
    video: "https://www.youtube.com/embed/BeQQH_SxIO0",
    title: "Video 40",
    cols: 1,
    rows: 1,
  },
  {
    video: "https://www.youtube.com/embed/7e7bsJOsQ0c?si=J3Y-1vt7NTkNXv4x",
    title: "Video 41",
    cols: 1,
    rows: 1,
  },
  {
    video: "https://www.youtube.com/embed/zammUObyvwc?si=9Qvq1C8BtNBh6YCZ",
    title: "Video 42",
    cols: 1,
    rows: 1,
  },
  {
    video: "https://www.youtube.com/embed/tgf-j-lzsJ4?si=Cfy4gWKztFhLDygA",
    title: "Video 43",
    cols: 1,
    rows: 1,
  },
  
];

