import React from "react";
import Header from "../components/reusable/Header/Header";
import Footer from "../components/reusable/Footer";
import ContactForm from "../components/contact/components/ContactForm";
import Map from "../components/contact/components/Map";
const Contact = () => {
    return (
        <div id="contact">

            <Header />
            <ContactForm />
            <Map />
            <Footer />
        </div>

    )
}

export default Contact;