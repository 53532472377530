import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import "swiper/css/bundle";
import "../styles/carousalStyle.css";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { EffectCoverflow, Pagination, Navigation, Autoplay } from 'swiper/modules';
import { images } from "../services/Carousal";

function Carousal() {
  const middleIndex = 5;
  
  return (
    <div className="container">
      <br/>
      <Swiper
        effect={'coverflow'}
        grabCursor={true}
        centeredSlides={true}
        loop={true}
        initialSlide={middleIndex}
        slidesPerView={'auto'}
        coverflowEffect={{
          rotate: 0,
          stretch: 0,
          depth: 100,
          modifier: 2.5,
        }}
        pagination={{ el: '.swiper-pagination', clickable: true }}
        navigation={{
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        }}
        autoplay={{
          delay: 3000, // Time between transitions in milliseconds
          disableOnInteraction: false, // Keeps autoplay running after user interaction
        }}
        modules={[EffectCoverflow, Pagination, Navigation, Autoplay]}
        className="swiper_container"
      >
        {images.map((e, index) => (
          <SwiperSlide key={index}>
            <img src={e} alt={`slide_image_${index}`} />
          </SwiperSlide>
        ))}
    
        <div className="slider-controler">
          <div className="swiper-button-prev slider-arrow">
            <ArrowBackIcon name="arrow-back-outline"/>
          </div>
          <div className="swiper-button-next slider-arrow">
            <ArrowForwardIcon name="arrow-forward-outline" />
          </div>
          <div className="swiper-pagination"></div>
        </div>
      </Swiper>
      <br/><br/>
    </div>
  );
}

export default Carousal;
