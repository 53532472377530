// import manimage from "../../images/manimage.png";
// import manimage1 from "../../contact/image/event.png";
import sing from "../../contact/image/signature.png";
import manimage from "../../contact/image/profieimgcontact.png"

const profileData = [
  {
    key: 1,
    aboutmentitle: "Profile", 
    p1: "Mr.Suraj",
    p2: " Patil",
    p3: " ",
    p4: " ",
    mainparagraph:
      "Event Manager Luxury Event Planner's In Town",
    signature: sing,
    meansrc: manimage,
    listcontent: [
      {
        key: 2,
        item: "RK Nagar Rd, beside Matoshree vridhashram, Dwarka Nagar, Shikshak Colony, Ratnappa Kumbhar Nagar, Kolhapur, Maharashtra 416013  ",
      },
      {
        key: 3,
        item: "",
      },
      {
        key: 4,
        item: " Mobile: +919405076050 +917972426493",
      },
    ],
  },
];

export default profileData;