import React, { useEffect, useState } from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import { itemData } from "../services/video";

export default function Video() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768); // Adjust the width threshold as needed
    };

    // Initial check
    handleResize();

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Sort the videos in descending order by title (or another criteria)
  const sortedItemData = [...itemData].reverse();

  return (
    <div style={{backgroundImage: "linear-gradient(-225deg, rgb(255, 254, 255) 0%, rgb(255, 253, 215) 100%)", paddingTop: "130px"}}>
      <ImageList
        sx={{ width: "100%", height: "auto" }}
        variant="quilted"
        cols={4}
        rowHeight={isMobile ? 121 : 200}
      >
        {sortedItemData.map((item) => (
          <ImageListItem key={item.video} cols={item.cols || 1} rows={item.rows || 1}>
            <iframe
              width="100%"
              height="100%"
              src={item.video}
              title={item.title}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              loading="lazy"
            />
          </ImageListItem>
        ))}
      </ImageList>
    </div>
  );
}
