import React from 'react';
import RingLoader from 'react-spinners/RingLoader';
import Box from '@mui/material/Box';

const Loader = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        backgroundColor: 'rgba(255, 255, 255, 0.8)', // Optional: adds a semi-transparent background
      }}
    >
      <RingLoader color="#36d7b7" size={60} />
    </Box>
  );
};

export default Loader;
