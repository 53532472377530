import React from 'react';
import { Grid } from '@mui/material';
import { StyledPaper, IconContainer, StyledTypography, Container, FiveLineEllipsis } from '../styles/joinusStyle';
import { HEADING_COLOR } from '../../../constants/style';
import { motion } from "framer-motion";
import { data } from "../services/WhyJoinUs";

const WhyJoinUs: React.FC = () => {
  return (
    <Container>
      <StyledTypography variant="h6" align="center" color={HEADING_COLOR} style={{ marginTop: '20px', fontSize: "13px", fontWeight: "bold" }}>
        TOP EVENTS
      </StyledTypography>
      <StyledTypography variant="h4" align="center" gutterBottom sx={{ fontFamily: 'auto' }}>
        Why Join Us?
      </StyledTypography>
      <motion.div
        initial={{ opacity: 0.0, y: 40 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{
          delay: 0.5,
          duration: 0.8,
          ease: "easeInOut",
        }}
      // className="relative flex flex-col gap-4 items-center justify-center px-4"
      >
        <Grid container spacing={4} justifyContent="center">

          {data.map((item, index) => (
            <Grid item xs={12} sm={6} md={3} key={index}>
              <div>
                <StyledPaper
                  bgGradient={item.bgGradient}
                  style={{
                    padding: '20px',
                    borderRadius: '15px',
                    boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
                    height: '300px', // Fixed height
                    backgroundColor: '#fff' // Ensure the background is not transparent
                  }}
                >
                  <IconContainer style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    height: '100%',
                    justifyContent: 'center'
                  }}>
                    {item.icon}
                    <StyledTypography
                      variant="h6"
                      gutterBottom
                      sx={{
                        fontFamily: 'auto',
                        marginTop: '10px',
                        color: '#fff'
                      }}
                    >
                      {item.title}
                    </StyledTypography>
                    <FiveLineEllipsis
                      variant="body2"
                      style={{
                        fontStyle: 'italic',
                        fontWeight: '600',
                        textAlign: 'center',
                        color: '#fff'
                      }}
                    >
                      {item.description}
                    </FiveLineEllipsis>
                  </IconContainer>
                </StyledPaper>
              </div>
            </Grid>
          ))}

        </Grid>
      </motion.div>
    </Container>
  );
};

export default WhyJoinUs;
