export const eventDescriptions: string[] = [
  "Destination Wedding",
  " Mehendi Setup",
  "Make-up Artist",
  "DJ Night Party",
  "Entrance Decoration & Rangoli",
  " LED Wall",
  "Special Entry",
  "Security & Vallet",
  " Receptions Parties",
  "Birthday Parties",
  "Catering & Food Services",
  "Balloon Decoration",
  "Stage Shows & Entertainment",
  "Food Counter Decor",
  "Seating Arrangement & Themes",
  "Theme Birthday Decoration",
  "Prof Anchor",
  "Photography & Video graphy",
  "SFX Firework",
  " Venue & Hotel",
  "Guest Management",
  " Wedding invites",
  "On the day co-ordination",
  " Hotel Booking for stay",
  "Pickup & Drop Services",
  "Post-marraige Ceremonies",
  "House Warming",
  "Home Decoration",
  // "Munj & anything else that you need!",
];
