import React, { useState } from "react";
import { Grid, IconButton } from '@mui/material';
import { Link } from "react-router-dom";
import { styled } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
import { Box, Drawer, List, Divider, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { MoveToInbox as InboxIcon, Mail as MailIcon } from '@mui/icons-material';
import "./header.css";
import { HEADING_COLOR } from "../../../constants/style";
import Loader from '../Loader/Loader'; // Import the Loader component

export const LinkTag = styled("label")(({ theme }) => ({
  padding: "15px",
  fontSize: "18px"
}));

const logo = "http://eventena.in/assets/images/logo.png";

function ResponsiveAppBar() {
  const [open, setOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false); // Add loading state

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  const handleLinkClick = (path: string) => () => {
    // Show loader before navigating
    setLoading(true);
    window.scrollTo(0, 0);

    // Simulate loading time
    setTimeout(() => {
      setLoading(false);
    }, 500); // Adjust the delay as needed
  };

  const DrawerList = (
    <Box sx={{ width: 250 }} role="presentation" onClick={toggleDrawer(false)}>
      <Link to="/" onClick={handleLinkClick('/')}>
      <div className="drawerImgLogoDiv">
        <img src={logo} className='drawerImgLogo' />
      </div>
      </Link>
      <Divider />
      <List>
        {['Home', 'Package', 'Contact', 'Gallery', 'Video'].map((text, index) => {
          const path = text === 'Home' ? '/' : `/${text.toLowerCase()}`; // Set path to '/' for Home, otherwise '/text'
          return (
            <ListItem key={text} disablePadding>
              <ListItemButton component={Link} to={path} onClick={handleLinkClick(path)}>
                <ListItemIcon sx={{ color: HEADING_COLOR }}>
                  {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                </ListItemIcon>
                <ListItemText primary={text} />
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>
      <Divider />
    </Box>
  );

  if (loading) {
    return <Loader />; // Show loader when loading is true
  }

  return (
    <div>
      <div className='mainHeaderDiv'>
        <div className='subHeaderDiv'>
          <Grid container spacing={0}>
            <Grid item xs={3} sm={2}>
            <Link to="/" onClick={handleLinkClick('/')}>
                <img src={logo} className='logoImg' />
              </Link>
            </Grid>
            <Grid item xs={9} sm={10}>
              <div className='deksptopHeaderMenus'>
                <LinkTag sx={{ fontWeight: "500" }}>
                  <Link to="/" onClick={handleLinkClick('/')}>Home</Link>
                </LinkTag>
                <LinkTag sx={{ fontWeight: "500" }}>
                  <Link to="/package" onClick={handleLinkClick('/package')}>Package</Link>
                </LinkTag>
                <LinkTag sx={{ fontWeight: "500" }}>
                  <Link to="/contact" onClick={handleLinkClick('/contact')}>Contact</Link>
                </LinkTag>
                <LinkTag sx={{ fontWeight: "500" }}>
                  <Link to="/gallery" onClick={handleLinkClick('/gallery')}>Gallery</Link>
                </LinkTag>
                <LinkTag sx={{ fontWeight: "500" }}>
                  <Link to="/video" onClick={handleLinkClick('/video')}>Video</Link>
                </LinkTag>
              </div>
              <div className='mobileHeaderMenu'>
                <IconButton aria-label="menu" size="large" sx={{ color: "black" }} onClick={toggleDrawer(true)}>
                  <MenuIcon />
                </IconButton>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <Drawer open={open} onClose={toggleDrawer(false)}>
        {DrawerList}
      </Drawer>
    </div>
  );
}

export default ResponsiveAppBar;
