import { styled } from "@mui/system";
import { Container, Typography } from "@mui/material";

export const MainContainer = styled("div")(({ theme }) => ({
  paddingBottom: "150px",
  paddingTop: "50px",
  position: "relative",
}));
export const StyledContainerFixed = styled("div")(({ theme }) => ({
  position: "absolute",
  bottom: "10%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
}));
export const StyledContainer = styled("div")(({ theme }) => ({
  textAlign: "center",
  paddingTop: "20px",
  backgroundColor: "white",
  borderRadius: "31px",
  boxShadow: "0px 0px 10px 4px #636c00",
  paddingBottom: "5%",
  width: "50%",
}));

export const StyledImage = styled("img")`
  display: block;
  margin: 0 auto;
  max-width: 100%;
  width: 100px;
  height: 89px;
`;

export const StyledText = styled(Typography)`
  margin-top: 10px;
  font-size: 34px;
  font-style: italic;
  color: #333;
  ${({ theme }) => theme.breakpoints.down("sm")} {
    font-size: 20px;
  }
`;
