import React, { useState } from 'react';
import { Typography, Rating } from '@mui/material';
import { TestimonialBox, QuoteIcon } from '../styles/testimonialStyle';
import Slider from "react-slick";
import { testimonials } from "../services/TestimonialCarousel";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const TestimonialCarousel: React.FC = () => {
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    speed: 3000,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div className='container'>
    <TestimonialBox>
      <Typography
        variant="h6"
        component="p"
        color="textPrimary"
        gutterBottom
        sx={{ fontFamily: "auto", fontSize: "24px" }}
      >
        <QuoteIcon /> <br />
        Testimonials
      </Typography>
      <Slider {...settings}>
        {testimonials.map((testimonial, index) => (
          <div key={index}>
            <div
              style={{
                padding: "20px",
                background: '#3f1536',
                borderRadius: "10px",
                color: "#fff",
                height: '330px',
                margin: '10px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
              }}
            >
              <Typography variant="body1" gutterBottom>
                {testimonial.quote}
              </Typography>
              <Rating
                name={`testimonial-rating-${index}`}
                value={testimonial.rating || 5} // Default rating set to 5 if not provided
                readOnly
              />
              <Typography variant="subtitle1">
                - {testimonial.name}, {testimonial.title}
              </Typography>
            </div>
          </div>
        ))}
      </Slider>
    </TestimonialBox>
    </div>
  );
};

export default TestimonialCarousel;
