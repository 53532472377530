import React from 'react';
import { Box, Typography, Grid } from '@mui/material';
import { styled } from '@mui/system';
import { HEADING_COLOR } from '../../../constants/style';
import { eventDescriptions } from "../services/otherService";
import { Container, StyledHeading, EventColumn, EventList } from "../styles/otherServiceStyle";
const splitArrayIntoColumns = (arr: string[], numColumns: number): string[][] => {
  const result: string[][] = Array.from({ length: numColumns }, () => []);
  arr.forEach((item, index) => {
    result[index % numColumns].push(item);
  });
  return result;
};
const [column1, column2, column3] = splitArrayIntoColumns(eventDescriptions, 3);


const App = () => {
  return (
    <Container>
      <br /><br />
      <Grid container spacing={4}>
        <Grid item xs={12} md={4}>
        </Grid>
        <Grid item xs={12} md={4}>
          <StyledHeading sx={{
            fontFamily: 'auto',
            textShadow: '2px 1px 3px #ffcd00'
          }}>
            Services
          </StyledHeading>
        </Grid>
        <br /><br />
        <Grid item xs={12} md={4}>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <EventColumn item xs={12} sm={4}>
          {column1.map((description, index) => (
            <EventList key={index}>{description}</EventList>
          ))}
        </EventColumn>
        <EventColumn item xs={12} sm={4}>
          {column2.map((description, index) => (
            <EventList key={index}>{description}</EventList>
          ))}
        </EventColumn>
        <EventColumn item xs={12} sm={4}>
          {column3.map((description, index) => (
            <EventList key={index}>{description}</EventList>
          ))}
        </EventColumn>
      </Grid>
      {/* <Grid container>
        <EventColumn item xs={12} sm={4}>
          
          <EventGrid>
            <EventList>Vendor and Venue Selection</EventList>
            <EventList>Event Concept & Theme Design</EventList>
            <EventList>Decoration Creation & Design</EventList>
            <EventList>Guest & Vendor Logistic & Coordination</EventList>
            <EventList>Full Service Event Day Management</EventList>
            <EventList>Event Consultation</EventList>
            <EventList>Budget Management</EventList>
            <EventList>and so much more!</EventList>
          </EventGrid>
        </EventColumn>
        <EventColumn item xs={12} sm={4}>
          
          <EventGrid>
            <EventList>Vendor and Venue Selection</EventList>
            <EventList>Event Concept & Theme Design</EventList>
            <EventList>Decoration Creation & Design</EventList>
            <EventList>Guest & Vendor Logistic & Coordination</EventList>
            <EventList>Full Service Event Day Management</EventList>
            <EventList>Event Consultation</EventList>
            <EventList>Budget Management</EventList>
            <EventList>and so much more!</EventList>
          </EventGrid>
        </EventColumn>
        <EventColumn item xs={12} sm={4}>
          
          <EventGrid>
            <EventList>Vendor and Venue Selection</EventList>
            <EventList>Event Concept & Theme Design</EventList>
            <EventList>Decoration Creation & Design</EventList>
            <EventList>Guest & Vendor Logistic & Coordination</EventList>
            <EventList>Full Service Event Day Management</EventList>
            <EventList>Event Consultation</EventList>
            <EventList>Budget Management</EventList>
            <EventList>and so much more!</EventList>
          </EventGrid>
        </EventColumn>
        
      </Grid> */}
      <br />
      <br />
      <h3 style={{
        textAlign: 'center',
        fontSize: 'larger',
        fontFamily: 'auto',
        fontStyle: 'italic',
        fontWeight: 600,
      }}>All Traditional & Corporate Events & anything else that you need!</h3>
      <br />
      <br />
    </Container>
  );
};

export default App;
