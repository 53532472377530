import React, { useEffect, useState } from 'react';
import { Tabs, Tab, Box } from '@mui/material';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import { Fade } from '@mui/material';
import { itemData, weddingsData, birthdayData, traditionalEventsData } from "../services/gallery";

// Define the type for the items in the gallery
interface GalleryItem {
  img: string;
  title: string;
  cols?: number;  // optional
  rows?: number;  // optional
}

export default function Gallery() {
  const [isMobile, setIsMobile] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const [galleryData, setGalleryData] = useState<GalleryItem[]>(itemData.reverse()); // Default to reversed itemData

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768); // Adjust the width threshold as needed
    };

    // Initial check
    handleResize();

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Cleanup listener on unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    // Update galleryData based on tabIndex and reverse the array
    switch (tabIndex) {
      case 0:
        setGalleryData([...itemData].reverse());
        break;
      case 1:
        setGalleryData([...weddingsData].reverse());
        break;
      case 2:
        setGalleryData([...birthdayData].reverse());
        break;
      case 3:
        setGalleryData([...traditionalEventsData].reverse());
        break;
        // case 4:
        //   setGalleryData([...themedEventsData].reverse());
        //   console.log("themedEventsData", themedEventsData);
        break;
      default:
        setGalleryData([]);
        break;
    }
  }, [tabIndex]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };

  return (
    <Box sx={{ width: '100%', paddingTop: isMobile ? '90px' : '130px' }}>
      <Tabs
        value={tabIndex}
        onChange={handleChange}
        centered={!isMobile} // Centered on desktop, full-width on mobile
        variant="scrollable" // Use 'scrollable' for mobile and desktop
        scrollButtons={isMobile ? "auto" : false} // Show scroll buttons on mobile, none on desktop
        orientation="horizontal" // Horizontal tabs on both mobile and desktop
        sx={{
          '& .MuiTabs-flexContainer': {
            flexWrap: isMobile ? 'wrap' : 'nowrap', // Allow wrapping on mobile
            justifyContent: isMobile ? 'center' : 'center', // Center tabs on mobile and desktop
            gap: 1, // Add some space between tabs
          },
          '& .MuiTab-root': {
            minWidth: isMobile ? 'auto' : '120px', // Adjust minWidth for mobile tabs
            textAlign: isMobile ? 'center' : 'left', // Center text on mobile
            // Remove any additional border styling
            borderBottom: 'none', // Ensure no border is applied to non-active tabs
            marginBottom: '-13px',
            textTransform: 'capitalize',
            fontSize: '16px',
          },
          '& .Mui-selected': {
            borderBottom: '2px solid #1976d2', // Single bottom border for the active tab
          },
          // Ensure the indicator is styled and visible
          '@media (max-width: 768px)': {
            '& .MuiTabs-indicator': {
              height: '1px', // Set the height of the indicator
              backgroundColor: 'white', // Set the color of the indicator
            },
          },
        }}
      >
        <Tab label="All" />
        <Tab label="Weddings" />
        <Tab label="Birthday" />
        <Tab label="Traditional Events" />
        {/* <Tab label="Themed Events" /> */}
      </Tabs>
      <ImageList
        sx={{ width: "100%", height: "auto", marginTop: 2 }}
        // variant="quilted"
        variant="masonry" // Change to "masonry" for better responsiveness on mobile
        cols={isMobile ? 2 : 4} // Adjust columns for mobile
        rowHeight={isMobile ? 121 : 200}
      >
        {/* {galleryData.map((item: GalleryItem, index) => (
          <ImageListItem key={`${item.img}-${index}`} cols={item.cols || 1} rows={item.rows || 1}>
            <img
              src={item.img}
              alt={item.title}
              loading="lazy"
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
            />
          </ImageListItem>
        ))} */}
        {galleryData.map((item: GalleryItem, index) => (
          <ImageListItem key={`${item.img}-${index}`} cols={item.cols || 1} rows={item.rows || 1}>
            <Fade in={true} timeout={500}>
              <img
                src={item.img}
                alt={item.title}
                loading="lazy"
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
            </Fade>
          </ImageListItem>
        ))}

      </ImageList>
    </Box>
  );
}
