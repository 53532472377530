import React from "react";
import { Grid } from '@mui/material';
import { styled } from '@mui/system';
import { MainContainer, StyledContainer, StyledText, StyledImage,StyledContainerFixed } from "../styles/sectionImagesTwoStyle";

const sectionTwoImg1 = "http://eventena.in/assets/images/sectionTwoImg1.jpg";
const sectionTwoImg2 = "http://eventena.in/assets/images/sectionTwoImg2.jpg";
const sectionTwoImg3 = "http://eventena.in/assets/images/sectionTwoImg3.jpg";
const logo = "http://eventena.in/assets/images/logo.png";
const StyledImg = styled('img')({
  width: '100%',
  height: '100%',
  objectFit: 'cover', // Ensure the image covers the entire container
});

const SectionImagesTwo = () => {
  return (
    <MainContainer>
      <Grid container spacing={2}>
        <Grid item xs={4} sx={{ 
          height: {
            xs: 300, // Mobile view
            md: 600  // Desktop view
          }
        }}>
          <StyledImg src={sectionTwoImg1} alt="1" />
        </Grid>
        <Grid item xs={4} sx={{ 
          height: {
            xs: 300, // Mobile view
            md: 600  // Desktop view
          }
        }}>
          <StyledImg src={sectionTwoImg3} alt="2" />
        </Grid>
        <Grid item xs={4} sx={{ 
          height: {
            xs: 300, // Mobile view
            md: 600  // Desktop view
          }
        }}>
          <StyledImg src={sectionTwoImg2} alt="3" />
        </Grid>
           
      </Grid>
     
      <StyledContainerFixed>
                <StyledContainer>
                    <StyledImage src={logo} alt="Event Image" />
                    <StyledText variant="h5">
                        Eventena Luxury Events and Parties
                    </StyledText>
                </StyledContainer>
            </StyledContainerFixed>
    </MainContainer>
  );
};

export default SectionImagesTwo;
