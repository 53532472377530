import {  Button } from '@mui/material';
import React from 'react';
import { BUTTON_STYLE } from '../../constants/style';
const ButtonComp = ({text,onClickFun,style}:{text:string,onClickFun:Function,style?:object}) =>{
  const newStyle= style ? {BUTTON_STYLE,...style} : {...BUTTON_STYLE}

    return <Button variant="contained" color="secondary"
    onClick={(e)=>onClickFun()}
    sx={newStyle}>
    {text}
  </Button>
}

export default ButtonComp;