import  birthday1 from "../../gallery/images/Birthday/birthday1.jpg"
import  birthday2 from "../../gallery/images/Birthday/birthday2.jpg"
import  birthday3 from "../../gallery/images/Birthday/birthday3.jpg"
import  birthday4 from "../../gallery/images/Birthday/birthday4.jpg"
import  birthday5 from "../../gallery/images/Birthday/birthday5.jpg"
import  birthday6 from "../../gallery/images/Birthday/birthday6.jpg"
import  birthday7 from "../../gallery/images/Birthday/birthday7.jpg"
import  birthday8 from "../../gallery/images/Birthday/birthday8.jpg"
import  birthday9 from "../../gallery/images/Birthday/birthday9.jpg"
import  birthday10 from "../../gallery/images/Birthday/birthday10.jpg"
import  birthday11 from "../../gallery/images/Birthday/birthday11.jpg"
import  birthday12 from "../../gallery/images/Birthday/birthday12.jpg"
import  birthday13 from "../../gallery/images/Birthday/birthday13.jpg"
import  birthday14 from "../../gallery/images/Birthday/birthday14.jpg"
import  birthday15 from "../../gallery/images/Birthday/birthday15.jpg"
import  birthday16 from "../../gallery/images/Birthday/birthday16.jpg"
import  birthday17 from "../../gallery/images/Birthday/birthday17.jpg"
import  birthday18 from "../../gallery/images/Birthday/birthday18.jpg"
import  birthday19 from "../../gallery/images/Birthday/birthday19.jpg"
import  birthday20 from "../../gallery/images/Birthday/birthday20.jpg"
import  birthday21 from "../../gallery/images/Birthday/birthday21.jpg"
import  birthday22 from "../../gallery/images/Birthday/birthday22.jpg"
import  birthday23 from "../../gallery/images/Birthday/birthday23.jpg"
import  birthday24 from "../../gallery/images/Birthday/birthday24.jpg"
import  birthday25 from "../../gallery/images/Birthday/birthday25.jpg"
import  birthday26 from "../../gallery/images/Birthday/birthday26.jpg"
import logoimage from "../../gallery/images/Logo/Eventenalogo-1.jpg"
import weddings1 from "../../gallery/images/Wedding/weddings1.jpg";
import weddings2 from "../../gallery/images/Wedding/weddings2.jpg";
import weddings3 from "../../gallery/images/Wedding/weddings3.jpg";
import weddings4 from "../../gallery/images/Wedding/weddings4.jpg";
import weddings5 from "../../gallery/images/Wedding/weddings5.jpg";
import weddings6 from "../../gallery/images/Wedding/weddings6.jpg";
import weddings7 from "../../gallery/images/Wedding/weddings7.jpg";
import weddings8 from "../../gallery/images/Wedding/weddings8.jpg";
import weddings9 from "../../gallery/images/Wedding/weddings9.jpg";
import weddings10 from "../../gallery/images/Wedding/weddings10.jpg";
import weddings11 from "../../gallery/images/Wedding/weddings11.jpg";
import weddings12 from "../../gallery/images/Wedding/weddings12.jpg";
import weddings13 from "../../gallery/images/Wedding/weddings13.jpg";
import weddings14 from "../../gallery/images/Wedding/weddings14.jpg";
import weddings15 from "../../gallery/images/Wedding/weddings15.jpg";
import weddings16 from "../../gallery/images/Wedding/weddings16.jpg";
import weddings17 from "../../gallery/images/Wedding/weddings17.jpg";
import weddings18 from "../../gallery/images/Wedding/weddings18.jpg";
import weddings19 from "../../gallery/images/Wedding/weddings19.jpg";
import weddings20 from "../../gallery/images/Wedding/weddings20.jpg";
import weddings21 from "../../gallery/images/Wedding/weddings21.jpg";
import weddings22 from "../../gallery/images/Wedding/weddings22.jpg";
import weddings23 from "../../gallery/images/Wedding/weddings23.jpg";
import weddings24 from "../../gallery/images/Wedding/weddings24.jpg";
import weddings25 from "../../gallery/images/Wedding/weddings25.jpg";
import weddings26 from "../../gallery/images/Wedding/weddings26.jpg";
import weddings27 from "../../gallery/images/Wedding/weddings27.jpg";
import weddings28 from "../../gallery/images/Wedding/weddings28.jpg";
import weddings29 from "../../gallery/images/Wedding/weddings29.jpg";
import weddings30 from "../../gallery/images/Wedding/weddings30.jpg";
import weddings31 from "../../gallery/images/Wedding/weddings31.jpg";
import weddings32 from "../../gallery/images/Wedding/weddings32.jpg";
import weddings33 from "../../gallery/images/Wedding/weddings33.jpg";
import weddings34 from "../../gallery/images/Wedding/weddings34.jpg";
import weddings35 from "../../gallery/images/Wedding/weddings35.jpg";
import weddings36 from "../../gallery/images/Wedding/weddings36.jpg";
import weddings37 from "../../gallery/images/Wedding/weddings37.jpg";
import weddings38 from "../../gallery/images/Wedding/weddings38.jpg";
import weddings39 from "../../gallery/images/Wedding/weddings39.jpg";
import weddings40 from "../../gallery/images/Wedding/weddings40.jpg";
import weddings41 from "../../gallery/images/Wedding/weddings41.jpg";
import weddings42 from "../../gallery/images/Wedding/weddings42.jpg";
import weddings43 from "../../gallery/images/Wedding/weddings43.jpg";
import weddings44 from "../../gallery/images/Wedding/weddings44.jpg";
import weddings45 from "../../gallery/images/Wedding/weddings45.jpg";
import weddings46 from "../../gallery/images/Wedding/weddings46.jpg";
import weddings47 from "../../gallery/images/Wedding/weddings47.jpg";
import weddings48 from "../../gallery/images/Wedding/weddings48.jpg";
import weddings49 from "../../gallery/images/Wedding/weddings49.jpg";
import weddings50 from "../../gallery/images/Wedding/weddings50.jpg";
import weddings51 from "../../gallery/images/Wedding/weddings51.jpg";
import weddings52 from "../../gallery/images/Wedding/weddings52.jpg";
import weddings53 from "../../gallery/images/Wedding/weddings53.jpg";
import weddings54 from "../../gallery/images/Wedding/weddings54.jpg";
import weddings55 from "../../gallery/images/Wedding/weddings55.jpg";
import weddings56 from "../../gallery/images/Wedding/weddings56.jpg";
import weddings57 from "../../gallery/images/Wedding/weddings57.jpg";
import weddings58 from "../../gallery/images/Wedding/weddings58.jpg";
import weddings59 from "../../gallery/images/Wedding/weddings59.jpg";
import weddings60 from "../../gallery/images/Wedding/weddings60.jpg";
import weddings61 from "../../gallery/images/Wedding/weddings61.jpg";
import weddings62 from "../../gallery/images/Wedding/weddings62.jpg";
import weddings63 from "../../gallery/images/Wedding/weddings63.jpg";
import weddings64 from "../../gallery/images/Wedding/weddings64.jpg";
import weddings65 from "../../gallery/images/Wedding/weddings65.jpg";
import weddings66 from "../../gallery/images/Wedding/weddings66.jpg";
import weddings67 from "../../gallery/images/Wedding/weddings67.jpg";
import weddings68 from "../../gallery/images/Wedding/weddings68.jpg";
import weddings69 from "../../gallery/images/Wedding/weddings69.jpg";
import weddings70 from "../../gallery/images/Wedding/weddings70.jpg";
import weddings71 from "../../gallery/images/Wedding/weddings71.jpg";
import weddings72 from "../../gallery/images/Wedding/weddings72.jpg";
import weddings73 from "../../gallery/images/Wedding/weddings73.jpg";
import weddings74 from "../../gallery/images/Wedding/weddings74.jpg";
import weddings75 from "../../gallery/images/Wedding/weddings75.jpg";
import weddings76 from "../../gallery/images/Wedding/weddings76.jpg";
import weddings77 from "../../gallery/images/Wedding/weddings77.jpg";
import weddings78 from "../../gallery/images/Wedding/weddings78.jpg";
import weddings79 from "../../gallery/images/Wedding/weddings79.jpg";
import weddings80 from "../../gallery/images/Wedding/weddings80.jpg";
import weddings81 from "../../gallery/images/Wedding/weddings81.jpg";
import weddings82 from "../../gallery/images/Wedding/weddings82.jpg";
import weddings83 from "../../gallery/images/Wedding/weddings83.jpg";
import weddings84 from "../../gallery/images/Wedding/weddings84.jpg";
import weddings85 from "../../gallery/images/Wedding/weddings85.jpg";
import weddings86 from "../../gallery/images/Wedding/weddings86.jpg";
import weddings87 from "../../gallery/images/Wedding/weddings87.jpg";
import weddings88 from "../../gallery/images/Wedding/weddings88.jpg";
import weddings89 from "../../gallery/images/Wedding/weddings89.jpg";
import weddings90 from "../../gallery/images/Wedding/weddings90.jpg";
import weddings91 from "../../gallery/images/Wedding/weddings91.jpg";
import weddings92 from "../../gallery/images/Wedding/weddings92.jpg";
import weddings93 from "../../gallery/images/Wedding/weddings93.jpg";
import weddings94 from "../../gallery/images/Wedding/weddings94.jpg";
import weddings95 from "../../gallery/images/Wedding/weddings95.jpg";
import weddings96 from "../../gallery/images/Wedding/weddings96.jpg";
import weddings97 from "../../gallery/images/Wedding/weddings97.jpg";
import weddings98 from "../../gallery/images/Wedding/weddings98.jpg";
import weddings99 from "../../gallery/images/Wedding/weddings99.jpg";
import weddings100 from "../../gallery/images/Wedding/weddings100.jpg";
import weddings101 from "../../gallery/images/Wedding/weddings101.jpg";

import traditional1 from "../../gallery/images/traditional/traditional1.jpg";
import traditional2 from "../../gallery/images/traditional/traditional2.jpg"; 
import traditional3 from "../../gallery/images/traditional/traditional3.jpg"; 
import traditional4 from "../../gallery/images/traditional/traditional4.jpg"; 
import traditional5 from "../../gallery/images/traditional/traditional5.jpg"; 
import traditional6 from "../../gallery/images/traditional/traditional6.jpg"; 
import traditional7 from "../../gallery/images/traditional/traditional7.jpg"; 
import traditional8 from "../../gallery/images/traditional/traditional8.jpg"; 
import traditional9 from "../../gallery/images/traditional/traditional9.jpg"; 
import traditional10 from "../../gallery/images/traditional/traditional10.jpg"; 
import traditional11 from "../../gallery/images/traditional/traditional11.jpg"; 
import traditional12 from "../../gallery/images/traditional/traditional12.jpg"; 
import traditional13 from "../../gallery/images/traditional/traditional13.jpg"; 
import traditional14 from "../../gallery/images/traditional/traditional14.jpg"; 
import traditional15 from "../../gallery/images/traditional/traditional15.jpg"; 
import traditional16 from "../../gallery/images/traditional/traditional16.jpg"; 



export const bigheight1 = "http://eventena.in/assets/images/gallery/bigheight1.jpg";
export const bigheight2 = "http://eventena.in/assets/images/gallery/bigheight2.jpg";
export const bigheight3 = "http://eventena.in/assets/images/gallery/bigheight3.jpg";
export const bigheight4 = "http://eventena.in/assets/images/gallery/bigheight4.jpg";
export const bigheight5 = "http://eventena.in/assets/images/gallery/bigheight5.jpg";
export const bigheight6 = "http://eventena.in/assets/images/gallery/bigheight6.jpg";
export const bigheight7 = "http://eventena.in/assets/images/gallery/bigheight7.jpg";
export const bigheight8 = "http://eventena.in/assets/images/gallery/bigheight8.jpg";
export const bigheight9 = "http://eventena.in/assets/images/gallery/bigheight9.jpg";
export const bigheight10 = "http://eventena.in/assets/images/gallery/bigheight10.jpg";
export const bigheight11 = "http://eventena.in/assets/images/gallery/bigheight11.jpg";
export const bigheight12 = "http://eventena.in/assets/images/gallery/bigheight12.jpg";
export const bigheight13 = "http://eventena.in/assets/images/gallery/bigheight13.jpg";
export const bigheight14 = "http://eventena.in/assets/images/gallery/bigheight14.jpg";
export const bigheight15 = "http://eventena.in/assets/images/gallery/bigheight15.jpg";
export const bigheight16 = "http://eventena.in/assets/images/gallery/bigheight16.jpg";
export const bigheight17 = "http://eventena.in/assets/images/gallery/bigheight17.jpg";
export const bigheight18 = "http://eventena.in/assets/images/gallery/bigheight18.jpg";

export const normalHeight1 = "http://eventena.in/assets/images/gallery/normalHeight1.jpg";
export const normalHeight2 = "http://eventena.in/assets/images/gallery/normalHeight2.jpg";
export const normalHeight3 = "http://eventena.in/assets/images/gallery/normalHeight3.jpg";
export const normalHeight4 = "http://eventena.in/assets/images/gallery/normalHeight4.jpg";
export const normalHeight5 = "http://eventena.in/assets/images/gallery/normalHeight5.jpg";
export const normalHeight6 = "http://eventena.in/assets/images/gallery/normalHeight6.jpg";
export const normalHeight7 = "http://eventena.in/assets/images/gallery/normalHeight7.jpg";
export const normalHeight8 = "http://eventena.in/assets/images/gallery/normalHeight8.jpg";
export const normalHeight9 = "http://eventena.in/assets/images/gallery/normalHeight9.jpg";
export const normalHeight10 = "http://eventena.in/assets/images/gallery/normalHeight10.jpg";
export const normalHeight11 = "http://eventena.in/assets/images/gallery/normalHeight11.jpg";
export const normalHeight12 = "http://eventena.in/assets/images/gallery/normalHeight12.jpg";
export const normalHeight13 = "http://eventena.in/assets/images/gallery/normalHeight13.jpg";
export const normalHeight14 = "http://eventena.in/assets/images/gallery/normalHeight14.jpg";
export const normalHeight15 = "http://eventena.in/assets/images/gallery/normalHeight15.jpg";
export const normalHeight16 = "http://eventena.in/assets/images/gallery/normalHeight16.jpg";
export const normalHeight17 = "http://eventena.in/assets/images/gallery/normalHeight17.jpg";
export const normalHeight18 = "http://eventena.in/assets/images/gallery/normalHeight18.jpg";




export const itemData = [
  
  {
    id: '1', // Unique ID
    img: bigheight18,
    title: 'Breakfast',
    rows: 2,
    cols: 2,
  },
  {
    id: '2',
    img: normalHeight10,
    title: 'Burger',
    rows: 2,
    cols: 1,
  },
  {
    id: '3',
    img: normalHeight2,
    title: 'Camera',
    rows: 2,
    cols: 1,
  },
  {
    id: '4',
    img: normalHeight3,
    title: 'Coffee',
    cols: 2,
    rows: 2,
  },
  {
    id: '5',
    img: normalHeight4,
    title: 'Hats',
    rows: 1,
    cols: 2,
  },
  {
    id: '6',
    img: bigheight2,
    title: 'Honey',
    author: '@arwinneil',
    rows: 2,
    cols: 2,
  },
  {
    id: '7',
    img: normalHeight5,
    title: 'Basketball',
    rows: 2,
    cols: 2,
  },
  {
    id: '8',
    img: normalHeight6,
    title: 'Fern',
    rows: 2,
    cols: 2,
  },
  {
    id: '9',
    img: bigheight3,
    title: 'Mushrooms',
    rows: 2,
    cols: 2,
  },
  {
    id: '10',
    img: normalHeight7,
    title: 'Tomato basil',
    rows: 2,
    cols: 2,
  },
  {
    id: '11',
    img: normalHeight8,
    title: 'Sea star',
    rows: 2,
    cols: 2,
  },
  {
    id: '12',
    img: normalHeight9,
    title: 'Bike',
    rows: 2,
    cols: 2,
  },
  {
    id: '13',
    img: normalHeight10,
    title: 'Hats',
    rows: 2,
    cols: 2,
  },
  {
    id: '14',
    img: bigheight4,
    title: 'Honey',
    author: '@arwinneil',
    rows: 2,
    cols: 2,
  },
  {
    id: '15',
    img: normalHeight11,
    title: 'Basketball',
    rows: 2,
    cols: 2,
  },
  {
    id: '16',
    img: normalHeight12,
    title: 'Fern',
    rows: 2,
    cols: 2,
  },
  {
    id: '17',
    img: bigheight5,
    title: 'Breakfast',
    rows: 2,
    cols: 2,
  },
  {
    id: '18',
    img: normalHeight13,
    title: 'Burger',
    rows: 2,
    cols: 2,
  },
  {
    id: '19',
    img: normalHeight14,
    title: 'Camera',
    rows: 2,
    cols: 2,
  },
  {
    id: '20',
    img: normalHeight15,
    title: 'Coffee',
    rows: 2,
    cols: 2,
  },
  {
    id: '21',
    img: normalHeight6,
    title: 'Hats',
    rows: 2,
    cols: 2,
  },
  {
    id: '22',
    img: bigheight6,
    title: 'Honey',
    author: '@arwinneil',
    rows: 2,
    cols: 2,
  },
  {
    id: '23',
    img: normalHeight17,
    title: 'Basketball',
    rows: 2,
    cols: 2,
  },
  {
    id: '24',
    img: normalHeight18,
    title: 'Fern',
    rows: 2,
    cols: 2,
  },
  {
    id: '25',
    img: bigheight7,
    title: 'Hats',
    cols: 2,
    rows: 2,
  },
  {
    id: '26',
    img: bigheight8,
    title: 'Honey',
    author: '@arwinneil',
    rows: 2,
    cols: 2,
  },
  {
    id: '27',
    img: bigheight9,
    title: 'Basketball',
    rows: 2,
    cols: 2,
  },
  {
    id: '28',
    img: bigheight10,
    title: 'Fern',
    rows: 2,
    cols: 2,
  },
  {
    id: '29',
    img: bigheight11,
    title: 'Breakfast',
    rows: 2,
    cols: 2,
  },
  {
    id: '30',
    img: bigheight12,
    title: 'Burger',
    rows: 2,
    cols: 2,
  },
  {
    id: '31',
    img: bigheight13,
    title: 'Camera',
    rows: 2,
    cols: 2,
  },
  {
    id: '32',
    img: bigheight14,
    title: 'Coffee',
    cols: 4,
    rows: 4,
  },
  {
    id: '33',
    img: bigheight15,
    title: 'Hats',
    rows: 4,
    cols: 3,
  },
  {
    id: '34',
    img: bigheight16,
    title: 'Honey',
    author: '@arwinneil',
    rows: 2,
    cols: 2,
  },
  {
    id: '35',
    img: bigheight17,
    title: 'Basketball',
     rows: 2,
    cols: 2,
  },
  {
    id: '36',
    img: bigheight1,
    title: 'Fern',
    rows: 2,
    cols: 1,
  },
  
  // Birthday
  { id: 'b1', img: birthday1, title: 'Birthday 1', rows: 2, cols: 2 },
  { id: 'b2', img: birthday2, title: 'Birthday 2', rows: 2, cols: 1 },
  { id: 'b3', img: birthday3, title: 'Birthday 3', rows: 1, cols: 1 },
  { id: 'b4', img: birthday4, title: 'Birthday 4', rows: 1, cols: 1 },
  { id: 'b5', img: birthday5, title: 'Birthday 5', rows: 2, cols: 2 },
  { id: 'b6', img: birthday6, title: 'Birthday 6', rows: 2, cols: 2 },
  { id: 'b7', img: birthday7, title: 'Birthday 7', rows: 1, cols: 1 },
  { id: 'b8', img: birthday8, title: 'Birthday 8', rows: 1, cols: 1 },
  { id: 'b9', img: birthday9, title: 'Birthday 9', rows: 2, cols: 2 },
  { id: 'b10', img: birthday10, title: 'Birthday 10', rows: 1, cols: 1 },
  { id: 'b11', img: birthday11, title: 'Birthday 11', rows: 1, cols: 1 },
  { id: 'b12', img: birthday12, title: 'Birthday 12', rows: 1, cols: 1 },
  { id: 'b13', img: birthday13, title: 'Birthday 13', rows: 1, cols: 1 },
  { id: 'b14', img: birthday14, title: 'Birthday 14', rows: 2, cols: 2 },
  { id: 'b15', img: birthday15, title: 'Birthday 15', rows: 1, cols: 1 },
  { id: 'b16', img: birthday16, title: 'Birthday 16', rows: 1, cols: 1 },
  { id: 'b17', img: birthday17, title: 'Birthday 17', rows: 2, cols: 2 },
  { id: 'b18', img: birthday18, title: 'Birthday 18', rows: 1, cols: 1 },
  { id: 'b19', img: birthday19, title: 'Birthday 19', rows: 1, cols: 1 },
  { id: 'b20', img: birthday20, title: 'Birthday 20', rows: 1, cols: 1 },
  { id: 'b21', img: birthday21, title: 'Birthday 21', rows: 1, cols: 1 },
  { id: 'b22', img: birthday22, title: 'Birthday 22', rows: 1, cols: 1 },
  { id: 'b23', img: birthday23, title: 'Birthday 23', rows: 1, cols: 1 },
  { id: 'b24', img: birthday24, title: 'Birthday 24', rows: 1, cols: 1 },
  { id: 'b25', img: birthday25, title: 'Birthday 25', rows: 1, cols: 1 },
  { id: 'b26', img: birthday26, title: 'Birthday 26', rows: 2, cols: 2 },
  { id: 'b27', img: logoimage, title: 'logoimage27', rows: 2, cols: 2 },

  // Wedding
  { id: 'w1', img: weddings1, title: 'Wedding 1', rows: 2, cols: 2 },
  { id: 'w2', img: weddings2, title: 'Wedding 2', rows: 1, cols: 1 },
  { id: 'w3', img: weddings3, title: 'Wedding 3', rows: 2, cols: 2 },
  { id: 'w4', img: weddings4, title: 'Wedding 4', rows: 2, cols: 1 },
  { id: 'w5', img: weddings5, title: 'Wedding 5', rows: 1, cols: 1 },
  { id: 'w6', img: weddings6, title: 'Wedding 6', rows: 1, cols: 2 },
  { id: 'w7', img: weddings7, title: 'Wedding 7', rows: 2, cols: 2 },
  { id: 'w8', img: weddings8, title: 'Wedding 8', rows: 1, cols: 1 },
  { id: 'w9', img: weddings9, title: 'Wedding 9', rows: 1, cols: 2 },
  { id: 'w10', img: weddings10, title: 'Wedding 10', rows: 2, cols: 1 },
  { id: 'w11', img: weddings11, title: 'Wedding 11', rows: 1, cols: 1 },
  { id: 'w12', img: weddings12, title: 'Wedding 12', rows: 1, cols: 2 },
  { id: 'w13', img: weddings13, title: 'Wedding 13', rows: 2, cols: 2 },
  { id: 'w14', img: weddings14, title: 'Wedding 14', rows: 1, cols: 1 },
  { id: 'w15', img: weddings15, title: 'Wedding 15', rows: 1, cols: 2 },
  { id: 'w16', img: weddings16, title: 'Wedding 16', rows: 2, cols: 1 },
  { id: 'w17', img: weddings17, title: 'Wedding 17', rows: 1, cols: 1 },
  { id: 'w18', img: weddings18, title: 'Wedding 18', rows: 1, cols: 2 },
  { id: 'w19', img: weddings19, title: 'Wedding 19', rows: 2, cols: 2 },
  { id: 'w20', img: weddings20, title: 'Wedding 20', rows: 1, cols: 1 },
  { id: 'w21', img: weddings21, title: 'Wedding 21', rows: 1, cols: 2 },
  { id: 'w22', img: weddings22, title: 'Wedding 22', rows: 2, cols: 1 },
  { id: 'w23', img: weddings23, title: 'Wedding 23', rows: 1, cols: 1 },
  { id: 'w24', img: weddings24, title: 'Wedding 24', rows: 1, cols: 2 },
  { id: 'w25', img: weddings25, title: 'Wedding 25', rows: 2, cols: 2 },
  { id: 'w26', img: weddings26, title: 'Wedding 26', rows: 1, cols: 1 },
  { id: 'w27', img: weddings27, title: 'Wedding 27', rows: 1, cols: 2 },
  { id: 'w28', img: weddings28, title: 'Wedding 28', rows: 2, cols: 1 },
  { id: 'w29', img: weddings29, title: 'Wedding 29', rows: 1, cols: 1 },
  { id: 'w30', img: weddings30, title: 'Wedding 30', rows: 1, cols: 2 },
  { id: 'w31', img: weddings31, title: 'Wedding 31', rows: 2, cols: 2 },
  { id: 'w32', img: weddings32, title: 'Wedding 32', rows: 1, cols: 1 },
  { id: 'w33', img: weddings33, title: 'Wedding 33', rows: 1, cols: 2 },
  { id: 'w34', img: weddings34, title: 'Wedding 34', rows: 2, cols: 1 },
  { id: 'w35', img: weddings35, title: 'Wedding 35', rows: 1, cols: 1 },
  { id: 'w36', img: weddings36, title: 'Wedding 36', rows: 2, cols: 2 },
  { id: 'w37', img: weddings37, title: 'Wedding 37', rows: 2, cols: 2 },
  { id: 'w38', img: weddings38, title: 'Wedding 38', rows: 1, cols: 1 },
  { id: 'w39', img: weddings39, title: 'Wedding 39', rows: 2, cols: 2 },
  { id: 'w40', img: weddings40, title: 'Wedding 40', rows: 2, cols: 1 },
  { id: 'w41', img: weddings41, title: 'Wedding 41', rows: 1, cols: 1 },
  { id: 'w42', img: weddings42, title: 'Wedding 42', rows: 2, cols: 2 },
  { id: 'w43', img: weddings43, title: 'Wedding 43', rows: 2, cols: 2 },
  { id: 'w44', img: weddings44, title: 'Wedding 44', rows: 1, cols: 1 },
  { id: 'w45', img: weddings45, title: 'Wedding 45', rows: 2, cols: 2 },
  { id: 'w46', img: weddings46, title: 'Wedding 46', rows: 2, cols: 1 },
  { id: 'w47', img: weddings47, title: 'Wedding 47', rows: 1, cols: 1 },
  { id: 'w48', img: weddings48, title: 'Wedding 48', rows: 1, cols: 2 },
  { id: 'w49', img: weddings49, title: 'Wedding 49', rows: 2, cols: 2 },
  { id: 'w50', img: weddings50, title: 'Wedding 50', rows: 1, cols: 1 },
  { id: 'w51', img: weddings51, title: 'Wedding 51', rows: 1, cols: 2 },
  { id: 'w52', img: weddings52, title: 'Wedding 52', rows: 2, cols: 1 },
  { id: 'w53', img: weddings53, title: 'Wedding 53', rows: 1, cols: 1 },
  { id: 'w54', img: weddings54, title: 'Wedding 54', rows: 1, cols: 2 },
  { id: 'w55', img: weddings55, title: 'Wedding 55', rows: 2, cols: 2 },
  { id: 'w56', img: weddings56, title: 'Wedding 56', rows: 1, cols: 1 },
  { id: 'w57', img: weddings57, title: 'Wedding 57', rows: 1, cols: 2 },
  { id: 'w58', img: weddings58, title: 'Wedding 58', rows: 2, cols: 1 },
  { id: 'w59', img: weddings59, title: 'Wedding 59', rows: 1, cols: 1 },
  { id: 'w60', img: weddings60, title: 'Wedding 60', rows: 1, cols: 2 },
  { id: 'w61', img: weddings61, title: 'Wedding 61', rows: 2, cols: 2 },
  { id: 'w62', img: weddings62, title: 'Wedding 62', rows: 1, cols: 1 },
  { id: 'w63', img: weddings63, title: 'Wedding 63', rows: 1, cols: 2 },
  { id: 'w64', img: weddings64, title: 'Wedding 64', rows: 2, cols: 1 },
  { id: 'w65', img: weddings65, title: 'Wedding 65', rows: 1, cols: 1 },
  { id: 'w66', img: weddings66, title: 'Wedding 66', rows: 1, cols: 2 },
  { id: 'w67', img: weddings67, title: 'Wedding 67', rows: 2, cols: 2 },
  { id: 'w68', img: weddings68, title: 'Wedding 68', rows: 1, cols: 1 },
  { id: 'w69', img: weddings69, title: 'Wedding 69', rows: 1, cols: 2 },
  { id: 'w70', img: weddings70, title: 'Wedding 70', rows: 2, cols: 1 },
  { id: 'w71', img: weddings71, title: 'Wedding 71', rows: 1, cols: 1 },
  { id: 'w72', img: weddings72, title: 'Wedding 72', rows: 1, cols: 2 },
  { id: 'w73', img: weddings73, title: 'Wedding 73', rows: 2, cols: 2 },
  { id: 'w74', img: weddings74, title: 'Wedding 74', rows: 1, cols: 1 },
  { id: 'w75', img: weddings75, title: 'Wedding 75', rows: 1, cols: 2 },
  { id: 'w76', img: weddings76, title: 'Wedding 76', rows: 2, cols: 1 },
  { id: 'w77', img: weddings77, title: 'Wedding 77', rows: 1, cols: 1 },
  { id: 'w78', img: weddings78, title: 'Wedding 78', rows: 1, cols: 2 },
  { id: 'w79', img: weddings79, title: 'Wedding 79', rows: 2, cols: 2 },
  { id: 'w80', img: weddings80, title: 'Wedding 80', rows: 1, cols: 1 },
  { id: 'w81', img: weddings81, title: 'Wedding 81', rows: 1, cols: 2 },
  { id: 'w82', img: weddings82, title: 'Wedding 82', rows: 2, cols: 1 },
  { id: 'w83', img: weddings83, title: 'Wedding 83', rows: 1, cols: 1 },
  { id: 'w84', img: weddings84, title: 'Wedding 84', rows: 1, cols: 2 },
  { id: 'w85', img: weddings85, title: 'Wedding 85', rows: 2, cols: 2 },
  { id: 'w86', img: weddings86, title: 'Wedding 86', rows: 1, cols: 2 },
  { id: 'w87', img: weddings87, title: 'Wedding 87', rows: 2, cols: 2 },
  { id: 'w88', img: weddings88, title: 'Wedding 88', rows: 2, cols: 1 },
  { id: 'w89', img: weddings89, title: 'Wedding 89', rows: 2, cols: 1 },
  { id: 'w90', img: weddings96, title: 'Wedding 90', rows: 2, cols: 2 },
  { id: 'w91', img: weddings97, title: 'Wedding 91', rows: 2, cols: 2 },
  { id: 'w92', img: weddings90, title: 'Wedding 92', rows: 2, cols: 2 },
  { id: 'w93', img: weddings91, title: 'Wedding 93', rows: 2, cols: 2 },
  { id: 'w94', img: weddings92, title: 'Wedding 94', rows: 2, cols: 2 },
  { id: 'w95', img: weddings98, title: 'Wedding 95', rows: 1, cols: 1 },
  { id: 'w96', img: weddings99, title: 'Wedding 96', rows: 1, cols: 1 },
  { id: 'w97', img: weddings100, title: 'Wedding 97', rows: 2, cols: 2 },
  { id: 'w98', img: weddings101, title: 'Wedding 98', rows: 2, cols: 2 },
  { id: 'w99', img: weddings93, title: 'Wedding 99', rows: 1, cols: 2 },
  { id: 'w100', img: weddings94, title: 'Wedding 100', rows: 2, cols: 1 },
  { id: 'w101', img: logoimage, title: 'logoimage101', rows: 2, cols: 2 },
  { id: 'w102', img: weddings95, title: 'Wedding 102', rows: 2, cols: 1 },
 
  
  
];


export const weddingsData = [
{ id: 'w1', img: weddings1, title: 'Wedding 1', rows: 2, cols: 2 },
  { id: 'w2', img: weddings2, title: 'Wedding 2', rows: 1, cols: 1 },
  { id: 'w3', img: weddings3, title: 'Wedding 3', rows: 1, cols: 2 },
  { id: 'w4', img: weddings4, title: 'Wedding 4', rows: 2, cols: 1 },
  { id: 'w5', img: weddings5, title: 'Wedding 5', rows: 1, cols: 1 },
  { id: 'w6', img: weddings6, title: 'Wedding 6', rows: 1, cols: 2 },
  { id: 'w7', img: weddings7, title: 'Wedding 7', rows: 2, cols: 2 },
  { id: 'w8', img: weddings8, title: 'Wedding 8', rows: 1, cols: 1 },
  { id: 'w9', img: weddings9, title: 'Wedding 9', rows: 2, cols: 2 },
  { id: 'w10', img: weddings10, title: 'Wedding 10', rows: 2, cols: 1 },
  { id: 'w11', img: weddings11, title: 'Wedding 11', rows: 1, cols: 1 },
  { id: 'w12', img: weddings12, title: 'Wedding 12', rows: 1, cols: 2 },
  { id: 'w13', img: weddings13, title: 'Wedding 13', rows: 2, cols: 2 },
  { id: 'w14', img: weddings14, title: 'Wedding 14', rows: 1, cols: 1 },
  { id: 'w15', img: weddings15, title: 'Wedding 15', rows: 1, cols: 2 },
  { id: 'w16', img: weddings16, title: 'Wedding 16', rows: 2, cols: 1 },
  { id: 'w17', img: weddings17, title: 'Wedding 17', rows: 1, cols: 1 },
  { id: 'w18', img: weddings18, title: 'Wedding 18', rows: 1, cols: 2 },
  { id: 'w19', img: weddings19, title: 'Wedding 19', rows: 2, cols: 2 },
  { id: 'w20', img: weddings20, title: 'Wedding 20', rows: 1, cols: 1 },
  { id: 'w21', img: weddings21, title: 'Wedding 21', rows: 1, cols: 2 },
  { id: 'w22', img: weddings22, title: 'Wedding 22', rows: 2, cols: 1 },
  { id: 'w23', img: weddings23, title: 'Wedding 23', rows: 1, cols: 1 },
  { id: 'w24', img: weddings24, title: 'Wedding 24', rows: 1, cols: 2 },
  { id: 'w25', img: weddings25, title: 'Wedding 25', rows: 2, cols: 2 },
  { id: 'w26', img: weddings26, title: 'Wedding 26', rows: 1, cols: 1 },
  { id: 'w27', img: weddings27, title: 'Wedding 27', rows: 1, cols: 2 },
  { id: 'w28', img: weddings28, title: 'Wedding 28', rows: 2, cols: 1 },
  { id: 'w29', img: weddings29, title: 'Wedding 29', rows: 1, cols: 1 },
  { id: 'w30', img: weddings30, title: 'Wedding 30', rows: 1, cols: 2 },
  { id: 'w31', img: weddings31, title: 'Wedding 31', rows: 2, cols: 2 },
  { id: 'w32', img: weddings32, title: 'Wedding 32', rows: 1, cols: 1 },
  { id: 'w33', img: weddings33, title: 'Wedding 33', rows: 1, cols: 2 },
  { id: 'w34', img: weddings34, title: 'Wedding 34', rows: 2, cols: 1 },
  { id: 'w35', img: weddings35, title: 'Wedding 35', rows: 1, cols: 1 },
  { id: 'w36', img: weddings36, title: 'Wedding 36', rows: 1, cols: 2 },
  { id: 'w37', img: weddings37, title: 'Wedding 37', rows: 2, cols: 2 },
  { id: 'w38', img: weddings38, title: 'Wedding 38', rows: 1, cols: 1 },
  { id: 'w39', img: weddings39, title: 'Wedding 39', rows: 1, cols: 2 },
  { id: 'w40', img: weddings40, title: 'Wedding 40', rows: 2, cols: 1 },
  { id: 'w41', img: weddings41, title: 'Wedding 41', rows: 1, cols: 1 },
  { id: 'w42', img: weddings42, title: 'Wedding 42', rows: 1, cols: 2 },
  { id: 'w43', img: weddings43, title: 'Wedding 43', rows: 2, cols: 2 },
  { id: 'w44', img: weddings44, title: 'Wedding 44', rows: 1, cols: 1 },
  { id: 'w45', img: weddings45, title: 'Wedding 45', rows: 1, cols: 2 },
  { id: 'w46', img: weddings46, title: 'Wedding 46', rows: 2, cols: 1 },
  { id: 'w47', img: weddings47, title: 'Wedding 47', rows: 1, cols: 1 },
  { id: 'w48', img: weddings48, title: 'Wedding 48', rows: 1, cols: 2 },
  { id: 'w49', img: weddings49, title: 'Wedding 49', rows: 2, cols: 2 },
  { id: 'w50', img: weddings50, title: 'Wedding 50', rows: 1, cols: 1 },
  { id: 'w51', img: weddings51, title: 'Wedding 51', rows: 1, cols: 2 },
  { id: 'w52', img: weddings52, title: 'Wedding 52', rows: 2, cols: 1 },
  { id: 'w53', img: weddings53, title: 'Wedding 53', rows: 1, cols: 1 },
  { id: 'w54', img: weddings54, title: 'Wedding 54', rows: 1, cols: 2 },
  { id: 'w55', img: weddings55, title: 'Wedding 55', rows: 2, cols: 2 },
  { id: 'w56', img: weddings56, title: 'Wedding 56', rows: 1, cols: 1 },
  { id: 'w57', img: weddings57, title: 'Wedding 57', rows: 1, cols: 2 },
  { id: 'w58', img: weddings58, title: 'Wedding 58', rows: 2, cols: 1 },
  { id: 'w59', img: weddings59, title: 'Wedding 59', rows: 1, cols: 1 },
  { id: 'w60', img: weddings60, title: 'Wedding 60', rows: 1, cols: 2 },
  { id: 'w61', img: weddings61, title: 'Wedding 61', rows: 2, cols: 2 },
  { id: 'w62', img: weddings62, title: 'Wedding 62', rows: 1, cols: 1 },
  { id: 'w63', img: weddings63, title: 'Wedding 63', rows: 1, cols: 2 },
  { id: 'w64', img: weddings64, title: 'Wedding 64', rows: 2, cols: 1 },
  { id: 'w65', img: weddings65, title: 'Wedding 65', rows: 1, cols: 1 },
  { id: 'w66', img: weddings66, title: 'Wedding 66', rows: 1, cols: 2 },
  { id: 'w67', img: weddings67, title: 'Wedding 67', rows: 2, cols: 2 },
  { id: 'w68', img: weddings68, title: 'Wedding 68', rows: 1, cols: 1 },
  { id: 'w69', img: weddings69, title: 'Wedding 69', rows: 1, cols: 2 },
  { id: 'w70', img: weddings70, title: 'Wedding 70', rows: 2, cols: 1 },
  { id: 'w71', img: weddings71, title: 'Wedding 71', rows: 1, cols: 1 },
  { id: 'w72', img: weddings72, title: 'Wedding 72', rows: 1, cols: 2 },
  { id: 'w73', img: weddings73, title: 'Wedding 73', rows: 2, cols: 2 },
  { id: 'w74', img: weddings74, title: 'Wedding 74', rows: 1, cols: 1 },
  { id: 'w75', img: weddings75, title: 'Wedding 75', rows: 1, cols: 2 },
  { id: 'w76', img: weddings76, title: 'Wedding 76', rows: 2, cols: 1 },
  { id: 'w77', img: weddings77, title: 'Wedding 77', rows: 1, cols: 1 },
  { id: 'w78', img: weddings78, title: 'Wedding 78', rows: 1, cols: 2 },
  { id: 'w79', img: weddings79, title: 'Wedding 79', rows: 2, cols: 2 },
  { id: 'w80', img: weddings80, title: 'Wedding 80', rows: 1, cols: 1 },
  { id: 'w81', img: weddings81, title: 'Wedding 81', rows: 1, cols: 2 },
  { id: 'w82', img: weddings82, title: 'Wedding 82', rows: 2, cols: 1 },
  { id: 'w83', img: weddings83, title: 'Wedding 83', rows: 1, cols: 1 },
  { id: 'w84', img: weddings84, title: 'Wedding 84', rows: 1, cols: 2 },
  { id: 'w85', img: weddings85, title: 'Wedding 85', rows: 2, cols: 2 },
  { id: 'w86', img: weddings86, title: 'Wedding 86', rows: 1, cols: 2 },
  { id: 'w87', img: weddings87, title: 'Wedding 87', rows: 1, cols: 2 },
  { id: 'w88', img: weddings88, title: 'Wedding 88', rows: 2, cols: 1 },
  { id: 'w89', img: weddings89, title: 'Wedding 89', rows: 2, cols: 1 },
  { id: 'w90', img: weddings96, title: 'Wedding 96', rows: 2, cols: 2 },
  { id: 'w91', img: weddings97, title: 'Wedding 97', rows: 2, cols: 2 },
  { id: 'w92', img: weddings90, title: 'Wedding 90', rows: 2, cols: 2 },
  { id: 'w93', img: weddings91, title: 'Wedding 91', rows: 2, cols: 2 },
  { id: 'w94', img: weddings99, title: 'Wedding 92', rows: 2, cols: 2 },
  { id: 'w95', img: weddings98, title: 'Wedding 98', rows: 1, cols: 1 },
  { id: 'w96', img: weddings92, title: 'Wedding 99', rows: 1, cols: 1 },
  { id: 'w97', img: weddings95, title: 'Wedding 100', rows: 2, cols: 2 },
  { id: 'w98', img: weddings101, title: 'Wedding 101', rows: 2, cols: 2 },
  { id: 'w99', img: logoimage, title: 'logoimage99', rows: 2, cols: 2 },
  { id: 'w100', img: weddings93, title: 'Wedding 93', rows: 1, cols: 2 },
  { id: 'w101', img: weddings94, title: 'Wedding 94', rows: 2, cols: 1 },
  { id: 'w102', img: weddings101, title: 'Wedding 95', rows: 2, cols: 1 },
  
  
];


export const birthdayData = [
  { id: 'b1', img: birthday1, title: 'Birthday 1', rows: 3, cols: 4 },
  { id: 'b2', img: birthday2, title: 'Birthday 2', rows: 1, cols: 1 },
  { id: 'b3', img: birthday3, title: 'Birthday 3', rows: 1, cols: 1 },
  { id: 'b4', img: birthday4, title: 'Birthday 4', rows: 1, cols: 1 },
  { id: 'b5', img: birthday5, title: 'Birthday 5', rows: 2, cols: 2 },
  { id: 'b6', img: birthday6, title: 'Birthday 6', rows: 1, cols: 1 },
  { id: 'b7', img: birthday7, title: 'Birthday 7', rows: 1, cols: 1 },
  { id: 'b8', img: birthday8, title: 'Birthday 8', rows: 1, cols: 1 },
  { id: 'b9', img: birthday9, title: 'Birthday 9', rows: 2, cols: 2 },
  { id: 'b10', img: birthday10, title: 'Birthday 10', rows: 1, cols: 1 },
  { id: 'b11', img: birthday11, title: 'Birthday 11', rows: 1, cols: 1 },
  { id: 'b12', img: birthday12, title: 'Birthday 12', rows: 1, cols: 1 },
  { id: 'b13', img: birthday13, title: 'Birthday 13', rows: 1, cols: 1 },
  { id: 'b14', img: birthday14, title: 'Birthday 14', rows: 2, cols: 2 },
  { id: 'b15', img: birthday15, title: 'Birthday 15', rows: 1, cols: 1 },
  { id: 'b16', img: birthday16, title: 'Birthday 16', rows: 1, cols: 1 },
  { id: 'b17', img: birthday17, title: 'Birthday 17', rows: 2, cols: 2 },
  { id: 'b18', img: birthday18, title: 'Birthday 18', rows: 1, cols: 1 },
  { id: 'b19', img: birthday19, title: 'Birthday 19', rows: 1, cols: 1 },
  { id: 'w20', img: logoimage, title: 'logoimage20', rows: 2, cols: 2 },
  { id: 'b21', img: birthday20, title: 'Birthday 21', rows: 1, cols: 1 },
  { id: 'b22', img: birthday21, title: 'Birthday 22', rows: 1, cols: 1 },
  { id: 'b23', img: birthday22, title: 'Birthday 23', rows: 1, cols: 1 },
  { id: 'b24', img: birthday23, title: 'Birthday 24', rows: 1, cols: 1 },
  { id: 'b25', img: birthday24, title: 'Birthday 25', rows: 1, cols: 1 },
  { id: 'b26', img: birthday25, title: 'Birthday 26', rows: 1, cols: 1 },
  { id: 'b27', img: birthday26, title: 'Birthday 27', rows: 2, cols: 2 }
    // Add more items with unique IDs
];


export const traditionalEventsData = [
  {
    id: 't1', 
    img: traditional1,
    title: 'Traditional Event 1',
    rows: 2,
    cols: 2,
  },
  {
    id: 't2', 
    img: traditional2,
    title: 'Traditional Event 2',
    rows: 2,
    cols: 2,
  },
  {
    id: 't3', 
    img: traditional3,
    title: 'Traditional Event 3',
    rows: 1,
    cols: 2,
  },
  {
    id: 't4', 
    img: traditional4,
    title: 'Traditional Event 4',
    rows: 2,
    cols: 2,
  },
  {
    id: 't5', 
    img: traditional5,
    title: 'Traditional Event 5',
    rows: 1,
    cols: 2,
  },
  {
    id: 't6', 
    img: traditional6,
    title: 'Traditional Event 6',
    rows: 2,
    cols: 2,
  },
  {
    id: 't7', 
    img: traditional7,
    title: 'Traditional Event 7',
    rows: 1,
    cols: 1,
  },
  {
    id: 't8', 
    img: traditional8,
    title: 'Traditional Event 8',
    rows: 1,
    cols: 1,
  },
  {
    id: 't9', 
    img: traditional9,
    title: 'Traditional Event 9',
    rows: 1,
    cols: 2,
  },
  {
    id: 't10', 
    img: traditional10,
    title: 'Traditional Event 10',
    rows: 2,
    cols: 2,
  },
  {
    id: 't11', 
    img: traditional11,
    title: 'Traditional Event 11',
    rows: 2,
    cols: 1,
  },
  {
    id: 't12', 
    img: traditional12,
    title: 'Traditional Event 12',
    rows: 2,
    cols: 1,
  },
  {
    id: 't13', 
    img: traditional13,
    title: 'Traditional Event 13',
    rows: 1,
    cols: 2,
  },
  {
    id: 't14', 
    img: traditional14,
    title: 'Traditional Event 14',
    rows: 2,
    cols: 2,
  },
  { id: 't15', img: logoimage, title: 'logoimage15', rows: 2, cols: 2 },
  {
    id: 't16', 
    img: traditional15,
    title: 'Traditional Event 16',
    rows: 1,
    cols: 1,
  },
  {
    id: 't17', 
    img: traditional16,
    title: 'Traditional Event 17',
    rows: 1,
    cols: 1,
  },
  {
    id: 't18', // Unique ID for privatePartiesData
    img: bigheight16,
    title: 'Private Party 18',
    rows: 2,
    cols: 2,
  },
  // Add more items with unique IDs
];
// export const themedEventsData = [
//     {
//       id: 't1', // Unique ID for themedEventsData
//       img: normalHeight4,
//       title: 'Themed Event 1',
//     },
//     // Add more items with unique IDs
// ];
  
  