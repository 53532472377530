import React from 'react';
import { Box } from '@mui/material';

const BottomRightTriangleCurve = ({
  mainDivStyle,colorCurveDIvStyle
}:any) => {
  return (
    <Box
      sx={{
        position: 'relative',
        width: '100%', 
        height: '39px',
        backgroundColor: '#ffffff00',
        marginTop: "47px",  
        overflow: 'hidden',
        ...mainDivStyle,
        '&::after': {
          content: '""',
          position: 'absolute',
          top: '0',
          right: '0',
          width: '0',
          height: '0',
          // borderLeft: '100px solid transparent',
          // borderTop: '100px solid transparent',
          // borderBottom: '100px solid orange', // Triangle color
          // borderRadius: '0 0 0 50px', // Adjust to curve the bottom-right corner
          borderRight: "53px solid #ffde16",
          /* border-left: 107px solid #614242; */
          /* border-top: 82px solid #089157; */
          borderBottom: "86px solid #ffde16",
          borderRadius: "130px 66px 77px 101px",
          ...colorCurveDIvStyle
        }
      }}
    />
  );
};

export default BottomRightTriangleCurve;
