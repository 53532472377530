import React, { useState } from "react";
import { Grid, CardMedia } from '@mui/material';
import { Container, Title, Heading, Description, QuoteBox, QuoteText } from '../styles/aboutusStyle';  // Import your styled components
import TriangleCurve from "../../reusable/TriangleCurve"
import { SHADOW_COLOR } from "../../../constants/style";
import ButtonComp from "../../reusable/ButtonComp";
import { motion } from "framer-motion";
import { AuroraBackground } from "../../reusable/AuroraBackground/aurora-background";
import { FlipWords } from "../../reusable/FlipWords/flip-words";
import { aboutImages, eventSlogans, textContent } from "../services/about";
import { useNavigate,Link } from 'react-router-dom';

function AboutUs() {
  const [loading, setLoading] = useState<boolean>(false); // Add loading state
  const navigate = useNavigate();
  const handleLinkClick = (path: string) => () => {
    // Show loader before navigating
    setLoading(true);
    window.scrollTo(0, 0);

    // Simulate loading time
    setTimeout(() => {
      setLoading(false);
      navigate(path); // Perform navigation after the loading is complete
    }, 500); // Adjust the delay as needed
  };
  return (

    <div style={{ backgroundImage: "linear-gradient(45deg, #e8951c, transparent)" }}>
      {/* <AuroraBackground> */}
      <motion.div
        initial={{ opacity: 0.0, y: 40 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{
          delay: 0.3,
          duration: 0.8,
          ease: "easeInOut",
        }}
        className="relative flex flex-col gap-4 items-center justify-center px-4"
      >

        <Container>
          <br /><br />
          <Grid container spacing={4}>
            <Grid item xs={12} md={7}>

              <Title variant="overline">
                {textContent.title1}
              </Title>
              <Heading variant="h4">
                {textContent.title2}<br />
                <FlipWords words={eventSlogans} duration={2800} />
              </Heading>
              <Description variant="body1">
                {textContent.title3}
              </Description>
              <QuoteBox>
                <QuoteText variant="h6">
                  {textContent.title4}
                  <br />
                  {textContent.title5}

                </QuoteText>
              </QuoteBox>
              <Description variant="body1">
                {textContent.title6}
              </Description>
              <ButtonComp text="Enquiry Now" onClickFun={handleLinkClick('/contact')} />

            </Grid>
            <Grid item xs={12} md={5}>
              <Grid container spacing={2} sx={{ marginTop: "10px" }}>
                <Grid item xs={6}>
                  <TriangleCurve />
                  <CardMedia
                    component="img"
                    height="200"
                    image={aboutImages[0]}
                    alt="Event Image 1"
                    sx={{ borderRadius: 1, marginTop: "15px", boxShadow: `0px 1px 13px 1px ${SHADOW_COLOR}` }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <CardMedia
                    component="img"
                    height="190"
                    image={aboutImages[1]}
                    alt="Event Image 3"
                    sx={{ borderRadius: 1, boxShadow: `0px 1px 13px 1px ${SHADOW_COLOR}` }}
                  />

                  <div style={{
                    display: "flex"
                  }}>
                    <CardMedia
                      component="img"
                      height="180"
                      image={aboutImages[2]}
                      alt="Event Image 2"
                      sx={{ borderRadius: 1, marginTop: "15px", width: "80%", boxShadow: `0px 1px 13px 1px ${SHADOW_COLOR}` }}
                    />
                    <TriangleCurve
                      mainDivStyle={{
                        "height": '25px',
                        "marginTop": "16px",
                        "marginLeft": "16px",
                        "width": '35px',
                      }}
                      colorCurveDIvStyle={{
                        top: "none",
                        bottom: 0,
                        right: 0,
                        width: "0%",
                        height: "0%",
                        borderLeft: "none",
                        border: "66px solid orange",
                        borderRadius: "32%",
                        left: "none"
                      }}
                    />
                  </div>

                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </motion.div>
      {/* </AuroraBackground> */}
    </div>


  );
}

export default AboutUs;
