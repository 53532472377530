import React from 'react';
import { useTheme } from '@mui/material/styles';
import { Grid, CardMedia, Typography } from '@mui/material';
import { WorkshopContainer, SectionTitle, SectionHeading, ThreeLineEllipsis } from '../styles/workshopSectionStyle';
import { CardBody, CardContainer, CardItem } from "../../reusable/AnimatedCard/3d-card";
import { motion } from "framer-motion";
import { cards } from "../services/WorkshopSection";

const WorkshopSection = () => {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';

  return (
    <WorkshopContainer>
      <SectionTitle variant="overline" color="secondary">
        Event agenda
      </SectionTitle>
      <SectionHeading variant="h4">
        Ready to start planning your event
      </SectionHeading>
      <motion.div
        initial={{ opacity: 0, y: 40 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{
          delay: 0.5,
          duration: 0.8, 
          ease: "easeInOut",
        }}
      >
        <Grid container spacing={4} justifyContent="center">
          {cards.map((e) => (
            <Grid item xs={12} md={3} key={e.id}>
              <CardContainer className="inter-var">
                <CardBody
                  className={`bg-gray-50 relative group/card ${
                    isDarkMode
                      ? 'dark:bg-black dark:border-white/[0.2] dark:hover:shadow-2xl dark:hover:shadow-emerald-500/[0.1]'
                      : ''
                  } border-black/[0.1] w-auto sm:w-[30rem] h-auto rounded-xl p-6 border`}
                >
                  <CardItem translateZ="100" className="w-full mt-4">
                    <CardMedia
                      component="img"
                      height="200"
                      image={e.image}
                      className="h-60 w-full object-cover rounded-xl group-hover/card:shadow-xl"
                      alt="Business conference"
                    />
                  </CardItem>
                  <br />
                  <CardItem
                    translateZ="50"
                    className="text-xl text-neutral-600"
                    style={{ color: isDarkMode ? 'white' : 'black', fontFamily: 'auto' }}
                  >
                    {e.title}
                  </CardItem>
                  <CardItem
                    as="p"
                    translateZ="60"
                    className="text-neutral-500 text-sm max-w-sm mt-2"
                    style={{ color: isDarkMode ? 'lightgray' : 'black' }}
                  >
                    <ThreeLineEllipsis variant="body1" sx={{ fontSize: "14px" }}>
                      {e.description}
                    </ThreeLineEllipsis>
                  </CardItem>
                </CardBody>
              </CardContainer>
            </Grid>
          ))}
        </Grid>
      </motion.div>
    </WorkshopContainer>
  );
};

export default WorkshopSection;
