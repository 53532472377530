import React, { useEffect, useState } from 'react';
import { Box, Typography, Button, Card, CardContent, Grid } from '@mui/material';
import { styled } from '@mui/system';
import ButtonComp from '../../reusable/ButtonComp';
import { HEADING_COLOR, SHADOW_COLOR } from '../../../constants/style';
import { Background, PricingContainer, PricingCard, PriceValue, CheckMark, CrossMark } from "../styles/pricingStyle";
import { Link } from "react-router-dom";
import Loader from '../../reusable/Loader/Loader';

const Pricing = () => {
  const [open, setOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false); // Add loading state

  const handleLinkClick = (path: string) => () => {
    // Show loader before navigating
    setLoading(true);
    window.scrollTo(0, 0);

    // Simulate loading time
    setTimeout(() => {
      setLoading(false);
    }, 500); // Adjust the delay as needed
  };

  if (loading) {
    return <Loader />; // Show loader when loading is true
  }
  return (
    <Background>
      <PricingContainer>
        <Typography variant="h5" style={{ marginBottom: '20px', fontFamily: 'math' }}></Typography>
        <Typography variant="h3" style={{
          marginBottom: '40px',
          fontFamily: 'auto',
          border: '1px solid',
          backgroundColor: '#000000d1',
          padding: '10px',
          color: 'rgb(191, 134, 84)',
          textShadow: 'rgb(25, 24, 24) 10px 0px 20px',
        }}> Luxury Event Planner in Town</Typography>
        <Grid container justifyContent="center" spacing={4}>
          <Grid item xs={12} sm={6} md={4} >
            <div>
              <PricingCard>
                <CardContent>
                  <Typography variant="h5" style={{
                    fontStyle: 'oblique',
                    fontWeight: '600',
                    backgroundColor: '#000000b3',
                    // fontFamily: 'cursive',
                    textShadow: '7px 0px 12px #f1eeee'
                  }}>Standard Package </Typography>
                  <br />
                  <PriceValue variant="h5" style={{
                    fontStyle: 'oblique',
                    fontWeight: '600',
                    fontFamily: 'cursive'
                  }}>35000/-</PriceValue>
                  <Typography variant="body2"></Typography>
                  <br />
                  {/* <Box my={2}>
                  <CheckMark variant="body1">✔ Stage Decoration</CheckMark>
                  <Typography variant="body2">(Workshop, snacks, etc.)</Typography>
                  <CrossMark variant="body1">✖ Live video</CrossMark>
                  <Typography variant="body2">(Can access online free)</Typography>
                  <CrossMark variant="body1">✖ Meet speaker</CrossMark>
                  <Typography variant="body2">(Ask question privately)</Typography>
                </Box> */}
                  <Link to="package" onClick={handleLinkClick('/package')}><ButtonComp text={'Show Package'} onClickFun={() => { }} /></Link>
                </CardContent>
              </PricingCard>
            </div>

          </Grid>
          <Grid item xs={12} sm={6} md={4} >
            <div>
              <PricingCard >
                <CardContent>
                  <Typography variant="h5" style={{
                    fontStyle: 'oblique',
                    fontWeight: '600',
                    backgroundColor: '#000000b3',
                    // fontFamily: 'cursive',
                    textShadow: '7px 0px 12px #f1eeee'
                  }}>Gold Package</Typography>
                  <br />
                  <PriceValue variant="h5" style={{
                    fontStyle: 'oblique',
                    fontWeight: '600',
                    fontFamily: 'cursive'
                  }}>135000 /-</PriceValue>
                  <Typography variant="body2"></Typography>
                  <br />
                  {/* <Box my={2}>
                    <CheckMark variant="body1">✔ Full conference</CheckMark>
                    <Typography variant="body2">(Workshop, snacks, etc.)</Typography>
                    <CheckMark variant="body1">✔ Live video</CheckMark>
                    <Typography variant="body2">(Can access online free)</Typography>
                    <CheckMark variant="body1">✔ Meet speaker</CheckMark>
                    <Typography variant="body2">(Ask question privately)</Typography>
                  </Box> */}
                  <Link to="package" onClick={handleLinkClick('/package')}><ButtonComp text={'Show Package'} onClickFun={() => { }} style={{ background: "white", color: HEADING_COLOR }} /></Link>
                </CardContent>
              </PricingCard>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={4} >
            <div>
              <PricingCard>
                <CardContent>
                  <Typography variant="h5" style={{
                    fontStyle: 'oblique',
                    fontWeight: '600',
                    backgroundColor: '#000000b3',
                    // fontFamily: 'cursive',
                    textShadow: '7px 0px 12px #f1eeee'
                  }}>Platinum Package</Typography>
                  <br />
                  <PriceValue variant="h5" style={{
                    fontStyle: 'oblique',
                    fontWeight: '600',
                    fontFamily: 'cursive'
                  }}>150000 /-</PriceValue>
                  <Typography variant="body2"></Typography>
                  <br />
                  {/* <Box my={2}>
                    <CheckMark variant="body1">✔ Full conference</CheckMark>
                    <Typography variant="body2">(Workshop, snacks, etc.)</Typography>
                    <CheckMark variant="body1">✔ Live video</CheckMark>
                    <Typography variant="body2">(Can access online free)</Typography>
                    <CheckMark variant="body1">✔ Meet speaker</CheckMark>
                    <Typography variant="body2">(Ask question privately)</Typography>
                  </Box> */}
                  <Link to="package" onClick={handleLinkClick('/package')}> <ButtonComp text={'Show Package'} onClickFun={() => { }} /></Link>
                </CardContent>
              </PricingCard>
            </div>
          </Grid>
        </Grid>
      </PricingContainer>
    </Background>
  );
};

export default Pricing;
