import { styled } from '@mui/system';
import { Box, Avatar, Typography } from '@mui/material';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import { HEADING_COLOR, SHADOW_COLOR } from '../../../constants/style';

export const TestimonialBox = styled(Box)(({ theme }) => ({
  textAlign: 'center',
  margin: '40px 0',
  paddingLeft: '2%',
  paddingRight: '2%',
  [theme.breakpoints.up('md')]: {
    paddingLeft: '5%',
    paddingRight: '5%',
  },
}));

export const QuoteIcon = styled(FormatQuoteIcon)(({ theme }) => ({
  fontSize: '63px !important',
  color: HEADING_COLOR
//   color: theme.palette.primary.main,
}));

export const AvatarStyled = styled(Avatar)({
  width: 80,
  height: 80,
  margin: '0 16px',
  boxShadow:`0px 0px 11px 7px ${SHADOW_COLOR} `
});

export const ImageBox = styled('img')({
  width: '100%',
  height: 'auto',
  borderRadius: '8px',
});
