import React from "react";
import Header from "../components/reusable/Header/Header";
import Footer from "../components/reusable/Footer";
import Videogallery from "../components/video/components/Video";
const Video = () => {
    return (
        <div id="video">
            <Header/>
            <Videogallery />
           <Footer/>
        </div>
      
    )
}

export default Video;