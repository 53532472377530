import React, { useState, ChangeEvent } from "react";
import { Form, Input, InputNumber, Button, Row, Col } from "antd";
import emailjs from "@emailjs/browser";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../contact/styles/contactstyle.css";
import SendIcon from "@mui/icons-material/Send";
import {
    InstagramOutlined,
    WhatsAppOutlined,
    FacebookOutlined,
} from "@ant-design/icons";


const Map: React.FC = () => {
    return (
        <>
            <div className="container"  style={{ marginBottom:"10px" }}>
                <Row>
                    <Col span={24}>
                        <div className="devminds-ifamemap">
                            <iframe
                              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3822.341803266301!2d74.233634!3d16.659770700000003!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc0ffd682d81875%3A0x654af9a9454be67f!2sEventena!5e0!3m2!1sen!2sin!4v1722846883509!5m2!1sen!2sin"  height="450"
                                style={{ width: "100%" }}
                                loading="lazy"
                                referrerPolicy="no-referrer-when-downgrade"
                            ></iframe>
                            
                        </div>
                    </Col>
                </Row>

            </div>
        </>
    );
};

export default Map;
