import React from 'react';
import AppRoutes from "./routes";
import { BrowserRouter } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AnimatedCursor from "react-animated-cursor";
import { faWhatsapp, faInstagram , faFacebook, faYoutube} from "@fortawesome/free-brands-svg-icons";
import "aos/dist/aos.css";
import "./App.css";

function App() {
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  return (
    <BrowserRouter>
      <AppRoutes />
      <div className="whatsappmaindiv">
        <div className="bottom-whatsapp">
          <a href="https://wa.me/9405076050" target="_blank" title='Whatsapp'>
            <FontAwesomeIcon icon={faWhatsapp} className="whatsappicon" />
            <i
              // className="fa fa-whatsapp text-white"
              style={{ fontSize: "25px" }}
            ></i>
          </a>
        </div>
      </div>
      <div className="Instagrammaindiv">
        <div className='bottom-instagram'>
        <a href="https://www.instagram.com/eventena_55/" target="_blank" title='Instagram'>
        <FontAwesomeIcon icon={faInstagram} />
        </a>
        </div>
      </div>
      <div className="Facbookmaindiv">
        <div className='bottom-Facbook'>
        <a href="https://www.facebook.com/profile.php?id=61563800494856&mibextid=ZbWKwL" target="_blank" title='Facebook'>
        <FontAwesomeIcon icon={faFacebook} />
        </a>
        </div>
      </div>
      <div className="Youtubemaindiv">
        <div className='bottom-Youtube'>
        <a href="https://www.youtube.com/channel/UChdK5xGyr_mZ8UET8yGCnog" target="_blank" title='Youtube'>
        <FontAwesomeIcon icon={faYoutube} />
        </a>
        </div>
      </div>
      {!isMobile && (
      <div className="cursor__dot">
        <AnimatedCursor
          innerSize={10}
          outerSize={44} 
          // color="255, 147, 1"
          color="0, 0, 1"
          outerAlpha={0.1}
          innerScale={0.7}
          outerScale={1.4}
          showSystemCursor={true}
          trailingSpeed={8}
          innerStyle={{ borderRadius: "50%" }} 
          outerStyle={{
            borderRadius: "50%",
            border: "2px solid rgba(255, 147, 1, 0.3)",
          }} 
        />
      </div>
       )}
    </BrowserRouter>
  );
}

export default App;
