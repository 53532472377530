import { Box, Typography, Button, Card, CardContent, Grid } from '@mui/material';
import { styled } from '@mui/system';
import { SHADOW_COLOR } from '../../../constants/style';
export const Background = styled(Box)({
    backgroundImage: 'url(https://www.paperlesspost.com/blog/wp-content/uploads/080822_Blog_DecorationsForAnOutdoorWedding_01-hero.png)',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '50px 0',
  });
  
  export  const PricingCard = styled(Card)(({ theme }) => ({
    minWidth: 275,
    backgroundColor:"#10101033",
    color:"white",
    // backgroundImage: 'linear-gradient(to left, rgb(219, 220, 215) 0%, rgb(221, 220, 215) 24%, rgb(226, 201, 204) 30%, rgb(231, 98, 125) 46%, rgb(184, 35, 90) 59%, rgb(128, 19, 87) 71%, rgb(61, 22, 53) 84%, rgb(28, 26, 39) 100%)',
    margin: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(1),
    },
    boxShadow:`0px 0px 11px 0px ${SHADOW_COLOR} `
  }));
  
  export  const PricingContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: 'cursive',
    flexDirection: 'column',
    width: '100%',
    position: 'relative',
    textAlign: 'center',
    color: '#fff',
    paddingBottom: '50px',
    marginLeft: '3%',
    marginRight: '3%',
    [theme.breakpoints.up('md')]: {
      marginLeft: '5%',
      marginRight: '5%',
    },
  }));
  
  export const PricingHeader = styled(Typography)(({ theme }) => ({
    marginBottom: theme.spacing(4),
    color: '#f50057',
    fontFamily: 'cursive',
  }));
  
  export const PriceValue = styled(Typography)(({ theme }) => ({
  //   color: theme.palette.primary.main,
  }));
  
  export const CheckMark = styled(Typography)(({ theme }) => ({
    color: '#4caf50',
  }));
  
  export const CrossMark = styled(Typography)(({ theme }) => ({
    color: '#f44336',
  }));