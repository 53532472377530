import React from "react";
import { Grid, Typography, Box, keyframes } from "@mui/material";
import iimagedotted from "../../contact/image/backroundottedimage.png";
import aboutsData from "../../contact/services/profile";
import { FlipWords } from "../../reusable/FlipWords/flip-words";

const Profilesection = () => {
    if (!aboutsData || aboutsData.length === 0) {
        return null;
    }

    const textArray = aboutsData.map((data) => data.aboutmentitle);
    console.log("text", textArray);

    const rotationAnimation = keyframes`
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  `;
  
    const roundingAnimation = keyframes`
    0% {
      border-radius: 0%;
    }
    50% {
      border-radius: 50%;
    }
    100% {
      border-radius: 0%;
    }
  `;
  
    return (
        <Box
            id="about"
            sx={{
                py: 6,
                px: 2,
                backgroundImage: 'linear-gradient(45deg, #89581a, transparent)',
                "@media (max-width: 600px)": {
                    py: 4,
                }
            }}
        >
            <Box sx={{ maxWidth: 1200, mx: "auto" }}>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={6}>
                        <Box>
                            <Typography
                                variant="subtitle1"
                                sx={{
                                    color: "#333",
                                    mb: 2,
                                    letterSpacing: "0.5px",
                                    lineHeight: 1.4,
                                    wordBreak: "break-word",
                                    fontSize: "18px",
                                    fontWeight: "600"
                                }}
                            >
                                {textArray && textArray.length > 0 && (
                                    <FlipWords words={textArray} duration={2800} />
                                )}
                            </Typography>
                            <Typography
                                variant="h3"
                                sx={{ fontWeight: 700, lineHeight: 1.2, mb: 3, fontFamily: "emoji", color:"#4f3a3a" }}
                            >
                                <span>{aboutsData[0].p1}</span> {aboutsData[0].p2} <br />
                                {/* {aboutsData[0].p3} <br /> {aboutsData[0].p4} */}
                            </Typography>
                            <Typography variant="body1" sx={{
                                color: "#4f3a3a", mb: 3, fontSize: '18px', fontStyle: 'italic',
                                fontWeight: '600'
                            }}>
                                {aboutsData[0].mainparagraph}
                            </Typography>
                            <Box sx={{ mb: 3 }}>
                                <img
                                    src={aboutsData[0].signature}
                                    alt="Signature"
                                    style={{ maxWidth: "100%", height: "auto" }}
                                />
                            </Box>
                            <Box component="ul" sx={{ listStyle: "none", p: 0, m: 0 }}>
                                {aboutsData[0].listcontent.map((item) => (
                                    <Box
                                        key={item.key}
                                        component="li"
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            color: "#4f3a3a",
                                            mb: 2,
                                            "&:hover": { color: "#4f3a3a" },
                                        }}
                                    >
                                        <Box sx={{ mr: 2 }}>
                                            <span className="icon-check" />
                                        </Box>
                                        <Typography variant="body1" sx={{
                                            fontSize: '18px', fontStyle: 'italic',
                                            fontWeight: '600', marginLeft: "-16px"
                                        }}>{item.item}</Typography>
                                    </Box>
                                ))}
                            </Box>
                        </Box>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Box sx={{ position: "relative" }}>
                            {/* <Box
                                sx={{
                                    position: "absolute",
                                    top: -20,
                                    left: -20,
                                    animation: `${roundingAnimation} 8s linear infinite, ${rotationAnimation} 8s linear infinite`,
                                    "@media (max-width: 600px)": {
                                        top: -10,
                                        left: -10,
                                        width: "60%",  // Adjust size for mobile view
                                    }
                                }}
                            >
                                <img
                                    src={iimagedotted}
                                    alt="Dotted Shape 1"
                                    style={{ maxWidth: "100%", height: "auto"  }}
                                />
                            </Box> */}
                            {/* <Box
                                sx={{
                                    position: "absolute",
                                    bottom: 46,
                                    right: -20,
                                    animation: `${roundingAnimation} 8s linear infinite, ${rotationAnimation} 8s linear infinite`,
                                    "@media (max-width: 600px)": {
                                        bottom: 10,
                                        right: 10,
                                        width: "60%",  // Adjust size for mobile view
                                    }
                                }}
                            >
                                <img
                                    src={iimagedotted}
                                    alt="Dotted Shape 2"
                                    style={{ maxWidth: "100%", height: "auto" }}
                                />
                            </Box> */}
                            <Box
                                sx={{
                                    position: "relative",
                                    overflow: "hidden",
                                    borderRadius: 2,
                                    border: "none",
                                    boxShadow: "none",
                                    "&:hover": { transform: "scale(1.05)", transition: "0.3s" },
                                    "@media (max-width: 768px)": {
                                        height: "auto",
                                        width: "100%"
                                    }
                                   
                                }}
                            >
                                <img
                                    src={aboutsData[0].meansrc}
                                    alt="Profile"
                                    style={{ width: "100%", height: "600px"  }}
                                />
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
};

export default Profilesection;
