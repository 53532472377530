import React,{useState} from 'react';
import { CardContent, Rating, Stack, Chip, Box, Grid, ListItemIcon, Button } from '@mui/material';
import { Shop, Star, Diamond, OtherHouses, EmojiEvents, LocalAtm, LocalMall, Category } from '@mui/icons-material';
import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import ListSubheader from '@mui/joy/ListSubheader';
import { Link } from "react-router-dom";
import { FiberManualRecord } from '@mui/icons-material';
import ListItemButton from '@mui/joy/ListItemButton';
import Sheet from '@mui/joy/Sheet';
import { styled } from '@mui/material/styles';
import { type PackageDetails } from '../services/packages';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Loader from '../../reusable/Loader/Loader';
import 'react-lazy-load-image-component/src/effects/blur.css'; // Optional: For blur effect
import {
  StyledCard,
  StyledAvatar,
  Header,
  Title,
  Subtitle,
  Description,
  TagContainer,
  ActionContainer,
  StyledImage
} from '../styles/PackageDetailsStyled';

const packageIcons: { [key: string]: typeof Shop } = {
  "Standard Package 1": Shop,
  "Standard Package 2": Star,
  "Standard Package 3": LocalMall,
  "Sliver Package": Category,
  "Gold Package": EmojiEvents,
  "Platinum Package": LocalAtm,
  "Diamond Package": Diamond,
  "Other Services": OtherHouses
};

const BazaarCard = React.memo(({ data, index }: { data: PackageDetails, index: number }) => {
  const Icon = packageIcons[data.name as keyof typeof packageIcons] || Shop;

  const [open, setOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false); // Add loading state

  const handleLinkClick = (path: string) => () => {
    // Show loader before navigating
    setLoading(true);
    window.scrollTo(0, 0);

    // Simulate loading time
    setTimeout(() => {
      setLoading(false);
    }, 500); // Adjust the delay as needed
  };

  if (loading) {
    return <Loader />; // Show loader when loading is true
  }
  return (
    <Grid item xs={12} sm={6} md={4} key={index}>
      <StyledCard>
        <Header>
          <StyledAvatar>
            <Icon />
          </StyledAvatar>
          <Box>
            <Title variant="h6">{data.name}</Title>
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              <Subtitle variant="body2">{data.price && "₹" + `${data.price}/-`} </Subtitle>
            </Box>
            <Box display="flex" alignItems="center">
              <Stack spacing={1}>
                <Rating name="size-small" defaultValue={5} size="small" />
              </Stack>
            </Box>
          </Box>
        </Header>
        <CardContent>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: "100%" }}>
            {data.image.map((img, imgIndex) => (
              <LazyLoadImage
              key={imgIndex} 
              alt="Description of image"
              height={250}
              src={img} // Use your image path
              effect="blur" // Optional: Can be "opacity", "black-and-white", etc.
              style={{maxWidth:"100%",height:"250px",  objectFit: "cover", }}
            />
              
            ))}
          </div>
          <br />
          <Sheet
            variant="outlined"
            sx={{
              width: "100%",
              maxHeight: 300,
              height: 300,
              overflow: 'auto',
              borderRadius: 'sm',
              backgroundColor: '#a19d9400',
              border: "none"
            }}
          >
            <List>
              {[...data.features].map((e, categoryIndex) => (
                <ListItem nested key={categoryIndex}>
                  {e.title && <ListSubheader sx={{
                    fontSize: '15px', color: 'white', fontStyle: "oblique",
                    fontWeight: "600",
                    backgroundColor: "#6b134c",
                    width: "100px",
                    borderRadius:"28px"
                  }} sticky>{e.title}</ListSubheader>}
                  <List>
                    {[...e.description].map((desc, index) => (
                      <ListItem key={index}>
                        <ListItemIcon sx={{ minWidth: "16px" }}>
                          <FiberManualRecord sx={{ fontSize: '0.75rem', color: '#643d1b' }} />
                        </ListItemIcon>
                        <ListItemButton sx={{
                          fontSize: '15px', color: '#643d1b', fontStyle: "oblique",
                          fontWeight: "600",
                        }}>{desc.name}</ListItemButton>
                      </ListItem>
                    ))}
                  </List>
                </ListItem>
              ))}
            </List>
          </Sheet>
          <br />
          <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <TagContainer>
              {data.tag ?
                <Chip label={data.tag} color="primary" size="small" style={{ marginRight: '4px', backgroundColor: "#6b134c" }} />
                : <Box sx={{ width: "100%", height: "27px" }}></Box>
              }
            </TagContainer>
            <Link to={"/gallery"} onClick={handleLinkClick('/gallery')}>
              <Button sx={{ fontWeight: "600" }}>More</Button>
            </Link>
          </Box>
        </CardContent>
      </StyledCard>
    </Grid>
  );
});

export default BazaarCard;
