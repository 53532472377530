import React from "react";
import { Grid } from '@mui/material';
import { styled } from '@mui/system';
import {sectionOneImages} from '../services/SectionOne';
import {MainContainer} from "../styles/sectionOneStyle";
const StyledImg = styled('img')({
  width: '100%',
  height: '100%',
  objectFit: 'cover', // Ensure the image covers the entire container
});

const SectionOne = () => {
  return (
    <MainContainer>
      <Grid container spacing={0}>
        <Grid item xs={3} sx={{ 
          height: {
            xs: 300, // Mobile view
            md: 600  // Desktop view
          }
        }}>
          <StyledImg src={sectionOneImages[0]} alt="1" />
        </Grid>
        <Grid item xs={3} container direction="column" sx={{ 
          height: {
            xs: 300, // Mobile view
            md: 600  // Desktop view
          }
        }}>
          <Grid item sx={{ height: '50%' }}>
            <StyledImg src={sectionOneImages[1]} alt="2" />
          </Grid>
          <Grid item sx={{ height: '50%' }}>
            <StyledImg src={sectionOneImages[2]} alt="3" />
          </Grid>
        </Grid>
        <Grid item xs={3} container direction="column" sx={{ 
          height: {
            xs: 300, // Mobile view
            md: 600  // Desktop view
          }
        }}>
          <Grid item sx={{ height: '50%' }}>
            <StyledImg src={sectionOneImages[3]} alt="4" />
          </Grid>
          <Grid item sx={{ height: '50%' }}>
            <StyledImg src={sectionOneImages[4]} alt="5" />
          </Grid>
        </Grid>
        <Grid item xs={3} container direction="column" sx={{ 
          height: {
            xs: 300, // Mobile view
            md: 600  // Desktop view
          }
        }}>
          <Grid item sx={{ height: '50%' }}>
            <StyledImg src={sectionOneImages[5]} alt="6" />
          </Grid>
          <Grid item sx={{ height: '50%' }}>
            <StyledImg src={sectionOneImages[6]} alt="7" />
          </Grid>
        </Grid>
      </Grid>
    </MainContainer>
  );
};

export default SectionOne;
