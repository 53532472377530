import React, { useState, ChangeEvent } from "react";
import { Form, Input, InputNumber, Button, Row, Col } from "antd";
import emailjs from "@emailjs/browser";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../contact/styles/contactstyle.css";
import SendIcon from "@mui/icons-material/Send";
import {
  InstagramOutlined,
  WhatsAppOutlined,
  FacebookOutlined,
  YoutubeOutlined
} from "@ant-design/icons";
import Profilesection from "./Profile";

const { TextArea } = Input;

interface FormValues {
  first_name: string;
  last_name: string;
  mobileNoo: string;
  message: string;
}

const Contact: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [formValues, setFormValues] = useState<FormValues>({
    first_name: "",
    last_name: "",
    mobileNoo: "",
    message: "",
  });

  const handleInputChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleNumberChange = (value: string | number | null) => {
    setFormValues({ ...formValues, mobileNoo: value ? value.toString() : "" });
  };

  const MessagesendEmail = () => {
    console.log("click");
    setLoading(true);
    if (
      formValues.message &&
      formValues.first_name &&
      formValues.last_name &&
      formValues.mobileNoo
    ) {
      const serviceID = "service_qu1a6gr";
      const templateID = "template_ktsg8g9";
      const APIkeys = "i6qkqkYdn3lEXEx5S";
      const formElement = document.getElementById("form") as HTMLFormElement | null;

      if (formElement) {
        emailjs.sendForm(serviceID, templateID, formElement, APIkeys).then(
          () => {
            toast.success("Email sent successfully!", {
              position: "top-right",
              autoClose: 5000,
            });
            setLoading(false);
            formElement.reset();
            setFormValues({ first_name: "", last_name: "", mobileNoo: "", message: "" });
          },
          (err) => {
            alert(JSON.stringify(err));
            toast.error("Email not sent!", {
              position: "top-right",
              autoClose: 5000,
            });
            setLoading(false);
          }
        );
      } else {
        setLoading(false);
        toast.error("Form element not found!", {
          position: "top-right",
          autoClose: 5000,
        });
      }
    } else {
      setLoading(false);
      toast.error("Please fill all fields!", {
        position: "top-right",
        autoClose: 5000,
      });
    }
  };

  return (
    <>
      <br />
      <br />
      <br />
      <div className="devminds-contactbg" id="contact">
        <div className="container pt-5">
          <h4
            className="text-dark contactmaintitle"
            style={{
              paddingLeft: "11px",
              paddingBottom: "50px",
            }}
          >
            {/* <Functiontext textArray={["Get In Touch"]} /> */}
          </h4>
        </div>
        <div className="container">
          <Form
            layout="vertical"
            name="basic"
            labelCol={{
              span: 8,
            }}
            onFinish={MessagesendEmail}
            autoComplete="off"
            id="form"
            className="devminndsfrom"
          >
            <Row gutter={[16, 16]}>
              <Col xs={24} sm={24} md={6} lg={8} xl={8}></Col>
              <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                <div className="container devminds-contact">
                  <Row gutter={16} className="devminds-Regform">
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <Form.Item
                        name="first_name"
                        rules={[
                          {
                            required: true,
                            message: "Please input your First Name!",
                          },
                        ]}
                      >
                        <Input
                          placeholder="First name"
                          className="form-control inputContact"
                          name="first_name"
                          onChange={handleInputChange}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <Form.Item
                        name="last_name"
                        rules={[
                          {
                            required: true,
                            message: "Please input your Last Name!",
                          },
                        ]}
                      >
                        <Input
                          placeholder="Last name"
                          className="form-control inputContact"
                          name="last_name"
                          onChange={handleInputChange}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Form.Item
                    name="mobileNoo"
                    rules={[
                      {
                        required: true,
                        message: "Please input your Mobile Number!",
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || String(value).length === 10) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error("Please input 10 numbers!")
                          );
                        },
                      }),
                    ]}
                  >
                    <InputNumber
                      className="form-control inputContact"
                      placeholder="Mobile no"
                      name="mobileNoo"
                      onChange={handleNumberChange}
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                  <Form.Item
                    name="message"
                    rules={[
                      {
                        required: true,
                        message: "Please input your Message!",
                      },
                    ]}
                  >
                    <TextArea
                      className="form-control inputContact"
                      placeholder="Message"
                      name="message"
                      onChange={handleInputChange}
                    />
                  </Form.Item>
                  <div className=" text-center">
                    <Button
                      // type="primary"
                      htmlType="submit"
                      className="contactsubmitb"
                      style={{ backgroundColor: "#451639", color: "white", border: "1px solid rgb(107, 105, 105)" }}
                      loading={loading}
                    >
                      Submit <SendIcon />
                    </Button>
                  </div>
                  <p className="devminds-social">Eventena</p>
                  <div style={{ textAlign: "center" }}>
                    <Button
                      className="devminds-handles"
                      href="https://www.instagram.com/eventena_55/"
                      target="_blank"
                    >
                      <InstagramOutlined />
                    </Button>
                    &nbsp;
                    <Button
                      className="devminds-handles"
                      href="https://wa.me/9405076050"
                      target="_blank"
                    >
                      <WhatsAppOutlined />
                    </Button>
                    &nbsp;
                    <Button
                      className="devminds-handles"
                      href="https://www.facebook.com/profile.php?id=61563800494856&mibextid=ZbWKwL"
                      target="_blank"
                    >
                      <FacebookOutlined />
                    </Button>
                    &nbsp;
                    <Button
                      className="devminds-handles"
                      href="https://www.youtube.com/channel/UChdK5xGyr_mZ8UET8yGCnog"
                      target="_blank"
                    >
                      <YoutubeOutlined />
                    </Button>
                  </div>
                </div>
              </Col>

              <Col xs={24} sm={24} md={6} lg={8} xl={8}></Col>
            </Row>
            {/* <Row>
              <Col span={24}>
                <div className="devminds-ifamemap">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3821.5491396739526!2d74.23203307491843!3d16.69943018407519!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc10135800b55d7%3A0xfad224f6f09a76ad!2sRoyal%20sign!5e0!3m2!1sen!2sin!4v1720357973756!5m2!1sen!2sin"
                    height="450"
                    style={{ border: "0", width: "100%" }}
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                  ></iframe>
                </div>
              </Col>
            </Row> */}
            <ToastContainer />
          </Form>

        </div>
      </div>
      {/* <Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Profilesection />
        </Col>
      </Row> */}
    </>
  );
};

export default Contact;
