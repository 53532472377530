import { styled } from '@mui/system';
import { Box, Typography, Card } from '@mui/material';
import {HEADING_COLOR,SHADOW_COLOR} from "../../../constants/style"
export const WorkshopContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4),
  backgroundColor: '#f9f9f9',
  marginLeft: '1%',
  marginRight: '1%',
  [theme.breakpoints.up('md')]: {
    marginLeft: '5%',
    marginRight: '5%',
  },
}));

export const SectionTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  display: 'block',
  textAlign: 'center',
  color:HEADING_COLOR

}));

export const SectionHeading = styled(Typography)(({ theme }) => ({
  textAlign: 'center',
  marginBottom: theme.spacing(4),
  fontFamily:'auto',
}));

export const WorkshopCard = styled(Card)(({ theme }) => ({
  height: '100%',
  boxShadow:`0px 0px 11px 0px ${SHADOW_COLOR} `
}));
export const ThreeLineEllipsis = styled(Typography)(({ theme }) => ({
  display: '-webkit-box',
  overflow: 'hidden',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: 3,
  textOverflow: 'ellipsis',
  lineHeight: '1.5em',  // Adjust this according to your typography settings
  maxHeight: '4.5em',   // 3 lines * 1.5em line height
}));