export const aboutImages: string[] = [
  "http://eventena.in/assets/images/aboutUsImg1.jpg",
  "http://eventena.in/assets/images/aboutUsImg2.jpg",
  "http://eventena.in/assets/images/aboutUsImg3.jpg"
];
export const eventSlogans = [
    "Extraordinary",
    "Memories",
    "Ideas",
    "Unforgettable",
    "Vision",
    "Dreams",
    "Planning"
  ];
export const textContent = {
    title1:" Organize the best event",
    title2:"  EVENTENA Luxury Event Planner's In Town ",
    title3:" We specialize in crafting unforgettable experiences tailored to your unique vision. With years of experience and a passion for perfection, our team is dedicated to making your special occasion truly memorable.",
    title4:"Wedding | Corporate Events | Birthday Partys & more ... ",
    title5:"Life is an Event , Make it Memorable.",
    title6:"We bring your dream events to life with precision, creativity, and a touch of elegance."
}