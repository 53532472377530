import React, { useState } from 'react';
import { Box, Grid, Typography, Container, IconButton } from '@mui/material';
import { Link } from "react-router-dom";
import FacebookIcon from '@mui/icons-material/Facebook';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import CallIcon from '@mui/icons-material/Call';
import EmailIcon from '@mui/icons-material/Email';
import HomeIcon from '@mui/icons-material/Home';
import { styled } from '@mui/system';
import Loader from '../Loader/Loader';
const logo = "http://eventena.in/assets/images/logo.png";
// Replace these with your own social media URLs
const socialMediaLinks = {
  facebook: 'https://www.facebook.com/profile.php?id=61563800494856&mibextid=ZbWKwL', targetfacebook: "_blank",
  WhatsApp: 'https://wa.me/9405076050', targetWhatsApp: "_blank",
  instagram: 'https://www.instagram.com/eventena_55/', targetinstagram: "_blank",
  YouTube: 'https://www.youtube.com/channel/UChdK5xGyr_mZ8UET8yGCnog', targetYouTube: "_blank",
};
export const ContainerDiv = styled(Box)(({ theme }) => ({
  backgroundImage: 'linear-gradient(to top, #d42934f2 0%, #170404 100%)',
  [theme.breakpoints.up('md')]: {
    backgroundImage: 'linear-gradient(to top, #d42934f2 0%, #170404 100%)',
  },
  color: "#ffffffa3",
  // textShadow:"1px 2px 6px #b1b1b1",
  py: 3,

}));
const Footer: React.FC = () => {
  const [open, setOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false); // Add loading state

  const handleLinkClick = (path: string) => () => {
    // Show loader before navigating
    setLoading(true);
    window.scrollTo(0, 0);

    // Simulate loading time
    setTimeout(() => {
      setLoading(false);
    }, 500); // Adjust the delay as needed
  };

  if (loading) {
    return <Loader />; // Show loader when loading is true
  }
  return (
    <ContainerDiv>
      <br /><br />
      <Container maxWidth={false}>

        <Grid container spacing={2} justifyContent="space-between">
          <Grid item xs={12} sm={12} md={3} sx={{ display: "flex", justifyContent: "center" }}>
            <img src={logo} style={{ height: "117px", marginTop: "10px", marginBottom: "50px" }} />
            {/* Add your logo component or image here */}
          </Grid>
          <Grid item xs={12} sm={12} md={2} className='text-center'>
            <Typography variant="subtitle1" sx={{ color: "#ffffffa3", textAlign: "center", fontWeight: 'bold' }} gutterBottom>
              PRODUCT
            </Typography>
            <Link to="/Package" style={{ textDecoration: "none", fontWeight: '600' }} color="inherit" onClick={handleLinkClick('/package')}>Basic</Link><br />
            <Link to="/Package" style={{ textDecoration: "none", fontWeight: '600' }} color="inherit" onClick={handleLinkClick('/package')} >Sliver</Link><br />
            <Link to="/Package" style={{ textDecoration: "none", fontWeight: '600' }} color="inherit" onClick={handleLinkClick('/package')} >Gold</Link><br />
            <Link to="/Package" style={{ textDecoration: "none", fontWeight: '600' }} color="inherit" onClick={handleLinkClick('/package')} >Package</Link>
          </Grid>
          <Grid item xs={12} sm={12} md={2} className='text-center'>
            <Typography variant="subtitle1" sx={{ color: "#ffffffa3", fontWeight: 'bold' }} gutterBottom>
              COMPANY
            </Typography>
            <Link to="/" style={{ textDecoration: "none", fontWeight: '600' }} color="inherit" onClick={handleLinkClick('/')} >Home</Link><br />
            <Link to="/gallery" style={{ textDecoration: "none", fontWeight: '600' }} color="inherit" onClick={handleLinkClick('/gallery')} >Gallery</Link><br />
            <Link to="/contact" style={{ textDecoration: "none", fontWeight: '600' }} color="inherit" onClick={handleLinkClick('/contact')} >Contact</Link><br />
            <Link to="/video" style={{ textDecoration: "none", fontWeight: '600' }} color="inherit" onClick={handleLinkClick('/videos')} >Videos</Link><br />

          </Grid>
          <Grid item xs={12} sm={12} md={2} className='text-center'>
            <Typography variant="subtitle1" sx={{ color: "#ffffffa3", fontWeight: 'bold' }} gutterBottom>
              Contact
            </Typography>
            <Link to="#" style={{ textDecoration: "none", fontWeight: '600' }} color="inherit" ><CallIcon sx={{ color: "#ffffffa3", fontWeight: '600' }} />  9405076050 , 7972426493</Link><br />
            <Link to="#" style={{ textDecoration: "none", fontWeight: '600' }} color="inherit" > <EmailIcon sx={{ color: "#ffffffa3", fontWeight: '600' }} /> eventenask@gmail.com</Link><br />
            <Link to="#" style={{ textDecoration: "none", fontWeight: '600' }} color="inherit" > <HomeIcon sx={{ color: "#ffffffa3", fontWeight: '600' }} /> R K Nagar  Kolhapur.</Link><br />
          </Grid>
          <Grid item xs={12} sm={12} md={2} className='text-center'>
            <Typography variant="subtitle1" sx={{ color: "#ffffffa3", fontWeight: 'bold' }} gutterBottom>
              SOCIAL MEDIA
            </Typography>
            <IconButton aria-label="Facebook" color="inherit" component="a" href={socialMediaLinks.facebook} target={socialMediaLinks.targetfacebook}>
              <FacebookIcon sx={{ color: "#ffffffa3" }} />
            </IconButton>
            <IconButton aria-label="Twitter" color="inherit" component="a" href={socialMediaLinks.WhatsApp} target={socialMediaLinks.targetWhatsApp}>
              <WhatsAppIcon sx={{ color: "#ffffffa3" }} />
            </IconButton>
            <IconButton aria-label="Instagram" color="inherit" component="a" href={socialMediaLinks.instagram} target={socialMediaLinks.targetinstagram}>
              <InstagramIcon sx={{ color: "#ffffffa3" }} />
            </IconButton>
            <IconButton aria-label="Instagram" color="inherit" component="a" href={socialMediaLinks.YouTube} target={socialMediaLinks.targetYouTube}>
              <YouTubeIcon sx={{ color: "#ffffffa3" }} />
            </IconButton>
          </Grid>
        </Grid>
        <a href="https://devminds.co.in/" color="inherit" className='text-center'>
          <Typography variant="body2" color="text.secondary" align="center" sx={{ pt: 4, color: "white" }}>
            © 2024 Eventena All Rights Reserved. Designed &amp; Maintained by
            <a href="https://devminds.co.in/" target="_blank" rel="noopener noreferrer" style={{ color: "white", textDecoration: "none" }}> Devminds Software
            </a>
          </Typography>
        </a>
        <br />
      </Container>
    </ContainerDiv>
  );
};

export default Footer;