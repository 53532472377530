import EventIcon from '@mui/icons-material/Event';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'; // Example icon
import StarIcon from '@mui/icons-material/Star'; // Example icon
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'; // Example icon
interface CardData {
    title: string;
    description: string;
    icon: React.ReactElement;
    bgGradient: string;
  }
  
 export const data: CardData[] = [
    // {
    //   title: "Customization Available ",
    //   description: "Explore our diverse range of event planning services with customization available to perfectly fit your vision. Tailor every detail to match your unique preferences and create an event that’s truly one-of-a-kind.",
    //   icon: <EventIcon style={{ fontSize: 40, color: 'yellow' }} />,
    //   bgGradient: 'linear-gradient(135deg, #0421a0, #b0bef1)',
    // },
    // {
    //   title: "Budget Friendly",
    //   description: "Experience exceptional event planning that’s both high-quality and budget-friendly. We deliver stunning results without breaking the bank, ensuring you get the best value for your investment.",
    //   icon: <ChangeHistoryIcon style={{ fontSize: 40, color: 'yellow' }} />,
    //   bgGradient: 'linear-gradient(135deg, #00906e, #82ffd7)',
    // },
    // {
    //   title: "Worked with 500+ Clients",
    //   description: "With over 500 clients served, our extensive experience ensures a seamless and professional event planning process. Trust us to bring our proven expertise to your next event.",
    //   icon: <InfoIcon style={{ fontSize: 40, color: 'yellow' }} />,
    //   bgGradient: 'linear-gradient(135deg, #d06c04, #ffe1c2)',
    // },
    // {
    //   title: "Multiple Services",
    //   description: "Discover our range of multiple services designed to cater to all your event planning needs. From intimate gatherings to grand celebrations, we offer comprehensive solutions for every occasion.",
    //   icon: <EmojiEventsIcon style={{ fontSize: 40, color: 'yellow' }} />,
    //   bgGradient: 'linear-gradient(135deg, #b40016, #ffbfc5)',
    // },

    {
      title: "Exceptional Service",
      description: "Discover a level of service that's both personal and professional. Our team is dedicated to making your event exceptional.",
      icon: <EventIcon style={{ fontSize: 30, color: 'white' }} />,
      bgGradient: 'linear-gradient(135deg, #000000f0, #feb47b)',
  },
  {
      title: "Affordable Excellence",
      description: "Enjoy top-tier event planning at a cost-effective price. We deliver outstanding quality without the high price tag.",
      icon: <AttachMoneyIcon style={{ fontSize: 30, color: 'white' }} />,
      bgGradient: 'linear-gradient(135deg, #000000f0, #feb47b)',
  },
  {
      title: "Experienced Professionals",
      description: "With years of experience, our team delivers events with precision and expertise. Trust us to handle every detail.",
      icon: <StarIcon style={{ fontSize: 30, color: 'white' }} />,
      bgGradient: 'linear-gradient(135deg, #000000f0, #feb47b)',
  },
  {
      title: "Comprehensive Solutions",
      description: "From intimate gatherings to grand celebrations, our range of services covers all your event planning needs.",
      icon: <ExpandMoreIcon style={{ fontSize: 30, color: 'white' }} />,
      bgGradient: 'linear-gradient(135deg, #000000f0, #feb47b)',
  },
  ];
  