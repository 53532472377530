import { styled } from '@mui/system';
import { Box, Typography, Button } from '@mui/material';
import {HEADING_COLOR,BUTTON_STYLE } from "../../../constants/style"
export const Container = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4),
  marginLeft: '1%',
  marginRight: '1%',
  backgroundColor: "#f0f8ff00",
  paddingTop: "0px",
  [theme.breakpoints.up('md')]: {
    marginLeft: '5%',
    marginRight: '5%',
  },
}));

export const MainContainer = styled("div")(({ theme }) => ({
  paddingTop: "50px",
  backgroundColor: "#ffffdc",
  [theme.breakpoints.up('md')]: {
    paddingTop: "0px",
  },
}));
export const Title = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  // color:HEADING_COLOR
  color: '#4d4d53'
}));

export const Heading = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(3),
  // fontFamily: '"Playwrite CU", cursive',
  fontFamily:"auto",
  fontOpticalSizing: "auto",
  fontWeight: 400,
  fontStyle: 'normal'
}));

export const Description = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(3),
}));

export const QuoteBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
  // backgroundColor: '#f0f0f0',
  color:"white",
  borderRadius: theme.shape.borderRadius,
  marginBottom: theme.spacing(3),
}));

export const QuoteText = styled(Typography)(({ theme }) => ({
  fontStyle: 'italic',
  marginBottom: theme.spacing(1),
}));

export const StyledButton = styled(Button)(({ theme }) => ({
  textTransform: 'none',
  background: "#f2ca00",
  boxShadow: "0px 0px 7px 3px #ff8585",
}));
