import React, { useEffect, useState } from 'react';
import { packages, type PackageDetails } from "../services/packages";
import PackageDetailsComp from "./PackageDetails";
import { Typography, Grid } from '@mui/material';
import { Container } from "../styles/PackageDetailsStyled";


export default function Package() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768); // Adjust the width threshold as needed
    };

    // Initial check
    handleResize();

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div style={{ backgroundImage: "linear-gradient(-225deg, #FFFEFF 0%, #fffdd7 100%)" }}>
      <div style={{ height: "150px", width: "100%" }}></div>
      <Container>
        <Typography variant='h4'
          sx={{
            paddingTop: "0px", color: "#70144f",
            fontStyle: "oblique",
            fontWeight: "600",
            // fontFamily: "cursive",
          }}>Packages :</Typography>
        <br />
        <Grid container spacing={2}>
          {
            packages.map((e: PackageDetails, index: number) => {
              return (

                <PackageDetailsComp data={e} index={index} />

              )
            })
          }
        </Grid>
        <br />
      </Container>
    </div>
  );
}
