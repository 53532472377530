import { styled } from '@mui/material/styles';
import { Paper, Box, Typography } from '@mui/material';
import { SHADOW_COLOR } from '../../../constants/style';

interface StyledPaperProps {
  bgGradient: string;
}
export const Container = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4),
  backgroundColor: '#fff',
  marginLeft: '1%',
  marginRight: '1%',
  [theme.breakpoints.up('md')]: {
    marginLeft: '5%',
    marginRight: '5%',
  },
}));
// export const StyledPaper = styled(Paper)<StyledPaperProps>(({ theme, bgGradient }) => ({
//   padding: theme.spacing(3),
//   background: bgGradient,
//   color: 'white',
//   textAlign: 'center',
//   boxShadow:`0px 0px 11px 0px ${SHADOW_COLOR} `,
 
// }));

export const StyledPaper = styled(Paper)<StyledPaperProps>(({ theme, bgGradient }) => ({
  padding: theme.spacing(3),
  background: bgGradient || '#fff', // Use gradient or fallback to white
  color: 'white',
  textAlign: 'center',
  boxShadow: `0px 0px 11px 0px ${SHADOW_COLOR}`,
  height: '300px', // Ensure fixed height is applied
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
}));


export const IconContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginBottom: theme.spacing(2),
}));

interface StyledTypographyProps {
  color?: string;
}

export const StyledTypography = styled(Typography)<StyledTypographyProps>(({ theme, color }) => ({
  color: color || 'inherit',
  margin: theme.spacing(2, 0),

}));

export const FiveLineEllipsis = styled(Typography)(({ theme }) => ({
  display: '-webkit-box',
  overflow: 'hidden',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: 5,
  textOverflow: 'ellipsis',
  lineHeight: '1.5em',  // Adjust this according to your typography settings
  maxHeight: '8.5em',   // 3 lines * 1.5em line height
}));