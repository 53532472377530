import React from 'react';
import { Route, Routes, BrowserRouter, HashRouter } from "react-router-dom";
import Home from './home';
import Gallery from "./gallery";
import Package from "./package";
import Contact from "./contact";
import Video from "./video";

const AppRoutes = () => {
  return (
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path='/gallery' element={<Gallery />} />
        <Route path='/contact' element={<Contact />} />
        <Route path='/package' element={<Package />} />
        <Route path='/video' element={<Video />} />
      </Routes>
    
  );
};

export default AppRoutes;