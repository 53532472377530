import { styled } from '@mui/system';
import { Grid,Typography, Box } from "@mui/material";
import { HEADING_COLOR } from '../../../constants/style';
  
export const Container = styled(Box)(({ theme }) => ({
    padding: theme.spacing(4),
    marginLeft: '1%',
    marginRight: '1%',
    // backgroundColor: "#f0f8ff00",
    backgroundImage: "url(https://getwallpapers.com/wallpaper/full/7/7/4/135893.jpg)",
    paddingTop: "0px",
    [theme.breakpoints.up('md')]: {
      marginLeft: '5%',
      marginRight: '5%',
    },
  }));
  export const StyledHeading = styled('h1')`
  text-align: center;
  font-size: 30px;
  font-weight: 500;
  color: ${HEADING_COLOR};
  letter-spacing: 1px;
  text-transform: uppercase;
  display: grid;
  grid-template-columns: 1fr max-content 1fr;
  grid-template-rows: 27px 0;
  grid-gap: 20px;
  align-items: center;

  &:after,
  &:before {
    content: '';
    display: block;
    border-bottom: 1px solid ${HEADING_COLOR};
    border-top: 1px solid ${HEADING_COLOR};
    height: 5px;
    background-color: #f8f8f8;
  }
`;


export const EventColumn = styled(Grid)`
  padding: 0 20px;
  text-align: center;
`;

export const EventList = styled(Typography)`
  font-size: 16px;
  margin-bottom: 10px;
  font-size: 19px;
  font-family: math;
  font-style: italic;
`;
