import Standardpackage2 from "../../package/image/Standard2.jpeg"
import Standardpackage3 from "../../package/image/standard3.jpg"
import silverdpackage from "../../package/image/silver_packege.jpg"
import Goaldpackage from "../../package/image/Goald_packege.jpg"
import platinumpackage from "../../package/image/platinum_packege.jpg"
import Diamondpackage from "../../package/image/Diamond_packege.jpg"
import otherpackage from "../../package/image/other_services.jpg"
export const aboutUsImg1 = "http://eventena.in/assets/images/aboutUsImg1.jpg";
// export const Standardpackage2 = "http://eventena.in/assets/images/Standard2.jpeg";
// export const Standardpackage3 = "http://eventena.in/assets/images/standard3.jpg";
// export const silverdpackage = "http://eventena.in/assets/images/silver_packege.jpg";
// export const Goaldpackage = "http://eventena.in/assets/images/Goald_packege.jpg";
// export const platinumpackage = "http://eventena.in/assets/images/platinum_packege.jpg";
// export const Diamondpackage = "http://eventena.in/assets/images/Diamond_packege.jpg";
// export const otherpackage = "http://eventena.in/assets/images/other_services.jpg";

// import aboutUsImg1 from "../../package/image/aboutUsImg1.jpg"

export interface PackageDetails {
  name: string;
  features: {
    title?: string;
    description: {
      name: string;
    }[];
  }[];
  price?: number;
  tag?: string;
  image: string[];
}
export const packages: PackageDetails[] = [
  {
    name: "Standard Package 1",
    features: [
      {
        description: [
          {
            name: "Stage Decoration: Flower Wall + Lighting Stand + LED Lights",
          },
          {
            name: "Ganesh Murti and Decor",
          },
          {
            name: "Vidhi Mandap",
          },
          {
            name: "Center Carpet",
          },
        ],
      },
    ],
    price: 28000,
    image: [aboutUsImg1],
  },
  {
    name: "Standard Package 2",
    features: [
      {
        description: [
          { name: "Stage Decoration: Flower Wall 12 x 08 ft." },
          { name: "Red Velvet Rose + Light Stand + LED Lights" },
          { name: "Vidhi Mandap" },
          { name: "Ganesh Murti and Decor" },
          { name: "Center Carpet" },
          { name: "Flower Pot x 06 no's" },
        ],
      },
    ],
    price: 31000,
    image: [Standardpackage2],
  },
  {
    name: "Standard Package 3",
    features: [
      {
        title: "",
        description: [
          { name: "Stage Decoration" },
          { name: "Ganesh Murti and Decor" },
          { name: "Vidhi Mandap" },
          { name: "Center Carpet" },
        ],
      },
      {
        title: "",
        description: [
          { name: 'Selfie Point (08" x 08" size)' },
          { name: "Flower pot x 6 no's" },
          { name: "Welcome Board with Esal Stand" },
          { name: "Chair Covers with Ribbon : 100" },
          { name: "Table Covers x 10 no's" },
        ],
      },
    ],
    price: 35000,
    image: [Standardpackage3],
  },
  {
    name: "Sliver Package",
    features: [
      {
        title: "",
        description: [
          { name: "Stage Diamond Decoration Setup" },
          { name: "Led Lights and Lighting" },
          { name: "Welcome Shree Ganesh Decor" },
          { name: "Photo Booth with Light" },
          { name: "Stage & Center Printed Carpet" },
          { name: "Chair Covers & Ribbon x 200 no's" },
        ],
      },
      {
        title: "",
        description: [
          { name: "Table Covers and Frill x 15 no's" },
          { name: "Vidhi Mandap with Decor & Stage 10 x 10 ft." },
          { name: "Welcome Board with esal stand" },
          { name: "Flower pots x 08 no's" },
          { name: "Horse for Groom" },
          { name: "Peacock Aasan Doly for Bride" },
        ],
      },
    ],
    price: 87000,
    tag: "Customization Available",
    image: [silverdpackage],
  },
  {
    name: "Gold Package",
    features: [
      {
        title: "Day 1",
        description: [
          { name: "  Stage Engagement Decor Setup" },
          { name: "Vidhi Chairs x 6 no's" },
          { name: "Stage & Center Printed Carpet" },
          { name: "Photo Booth with Light" },
          { name: "Welcome Board with Esal Stand" },
          { name: " Haldi Setup 10 x 12 ft." },
          { name: " Haldi Kund x 2 no's" },
          { name: "Haldi Welcome Board with esal stand" },
          { name: "10 x 12 ft. Box entrance" },
        ],
      },
      {
        title: "Day 2",
        description: [
          { name: "Stage Diamond Decoration Setup" },
          { name: "Led Lights and Lighting" },
          { name: "Welcome Shree Ganesh Decor" },
          { name: "Photo Booth with Light" },
          { name: "Stage & Center Printed Carpet" },
          { name: "Chair Covers & Ribbon x 200 " },
          { name: "Table Covers and Frill x 15" },
          { name: "Vidhi Mandap with Decor & Stage 10 x 10 ft." },
          { name: "Welcome Board with esal stand" },
          { name: "Flower pots x 08" },
          { name: "Horse for Groom" },
          { name: "Peacock Aasan Doly for Bride" },
          { name: "10 x 12 ft. Box Entrance" },
        ],
      },
    ],
    price: 150000,
    tag: "Customization Available",
    image: [Goaldpackage],
  },
  {
    name: "Platinum Package",
    features: [
      {
        title: "Day 1",
        description: [
          { name: "Sangeet Stage Decor Setup with Screen" },
          { name: "Sound system with Mic(s)" },
          { name: "Professional ANCHOR" },
          { name: "Photo Booth with Light" },
          { name: "Stage & Center Printed Carpet" },
          { name: "Haldi Setup 10 x 12 ft." },
          { name: "Table Covers and Frill x 15 no's" },
          { name: "Vidhi Mandap with Decor & Stage 10 x 10 ft." },
          { name: "Welcome Board with esal stand" },
          { name: "Chair Covers & Ribbon x 100" },
          { name: " Haldi Kund x 2" },
          { name: " Haldi Welcome Board with Esal Stand" },
        ],
      },
      {
        title: "Day 2",
        description: [
          { name: "Stage Diamond Decoration Setup" },
          { name: "Led Lights and Lighting" },
          { name: "Welcome Shree Ganesh Decor" },
          { name: "Photo Booth with Light" },
          { name: "Stage & Center Printed Carpet" },
          { name: "Chair Covers & Ribbon x 200" },
          { name: "Table Covers and Frill x 15" },
          { name: "Vidhi Mandap with Decor & Stage 10 x 10 ft." },
          { name: "Welcome Board with esal stand" },
          { name: "Flower pots x 08" },
          { name: "Horse for Groom" },
          { name: "Peacock Aasan Doly for Bride" },
          { name: "10 x 12 ft. Box Entrance" },
        ],
      },
    ],
    price: 200000,
    tag: "Customization Available",
    image: [platinumpackage],
  },
  {
    name: "Diamond Package",
    features: [
      {
        title: "",
        description: [
          { name: "Engagement Stage Diamond Decoration Setup" },
          { name: " Engagement Ring Plate" },
          { name: "Stage and Center Carpet for 4 Functions" },
          { name: "Chair covers x 100 no's" },
          { name: "Welcome board" },
          { name: " Cold fire x 04 no's" },
          { name: "Sofa x 01 no." },
          { name: "Mehendi setup" },
          { name: "Mehendi Sofa" },
          { name: "Mehendi artist for 5 people's" },
          { name: "Horse for Groom" },
          { name: "Haldi Decoration setup" },
          { name: "Haldi kund" },
          { name: "Haldi Welcome Board" },
          { name: "LED lights for all functions" },
          { name: "Sangeet screen setup" },
          { name: " Sound system" },
        ],
      },
      {
        title: "",
        description: [
          { name: " Professional. Anchor" },
          { name: "Sangeet Welcome board" },
          { name: "Entry cold fire x 06 no's" },
          { name: "Photo wall" },
          {
            name: "Main entry 20ft by warm light dome • Wedding premium Decoration setup",
          },
          { name: "Chair cover and ribbon 300" },
          { name: "Stage vidhi mandop with Decor" },
          { name: "Wedding Welcome board" },
          { name: "Flower pots x 08" },
          { name: "House Decor" },
          { name: "Doly" },
          { name: "Band Baja x 10 people" },
          { name: "Paper blast x 04 no" },
          { name: "Co ordinators x 06 for all functions" },
          { name: "Car Decorations x 03 no's" },
        ],
      },
    ],
    price: 299000,
    tag: "Customization Available",
    image: [Diamondpackage],
  },
  {
    name: "Other Services",
    features: [
      {
        title: "",
        description: [
          { name: "Destination Wedding" },
          { name: " Mehendi Setup" },
          { name: "Make-up Artist" },
          { name: "DJ Night Party" },
          { name: "Entrance Decoration & Rangoli" },
          { name: " LED Wall" },
          { name: "Special Entry" },
          { name: "Security & Vallet" },
          { name: " Receptions Parties" },
          { name: "Birthday Decoration" },
          { name: "Catering & Food Services" },
          { name: "Balloon Decoration" },
          { name: "Stage Shows & Entertainment" },
          { name: "Food Counter Decor" },
          { name: "Seating Arrangement & Themes" },
          { name: "Theme Birthday Decoration" },
          { name: "Prof Anchor" },
          { name: "Photography & Video graphy" },
        ],
      },
      {
        title: "",
        description: [
          { name: "SFX Firework" },
          { name: " Venue & Hotel" },
          { name: "Guest Management" },
          { name: " Wedding invites" },
          { name: "On the day co-ordination" },
          { name: " Hotel Booking for stay" },
          { name: "Pickup & Drop Services" },
          { name: "Post-marraige Ceremonies" },
          { name: "House Warming" },
          { name: "Home Decoration" },
          { name: "Munj & anything else that you need!" },
        ],
      },
    ],
    image: [otherpackage],
  },
];


 