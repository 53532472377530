interface Testimonial {
  quote: string;
  name: string;
  title: string;
  rating: number;
}
export const testimonials: Testimonial[] = [
  {
    quote:
      "I had attended my friend's wedding where the decor was by The Perfect Wedding. The decor was amazing and the way they had taken care of the minutest things in the wedding was commendable. Keep rocking guys!!!",
    name: "Divya",
    title: "",
    rating: 5,
  },
  {
    quote:
      "Thank you so much for making our special occasion memorable... Starting from the approach, planning, and execution of the event, everything was pleasant. The way they deal and organize is fantastic. A big Cheers to the whole team events.",
    name: "Kannammai Shanmugam",
    title: "",
    rating: 5,
  },
  {
    quote:
      "Such a great work from S.K. event management and we were fully satisfied with their event organized for our function. Thank you for making our function more colorful. Keep going!",
    name: "Ajith G",
    title: "",
    rating: 5,
  },
  {
    quote:
      "Having you people on the occasion made a huge difference, you always find a way to get it done & all done well, even when the going gets tough you continue to have the best attitude. Keep going!",
    name: "Ashvini",
    title: "",
    rating: 5,
  },
  {
    quote:
      "Best event management in my brother's engagement, wedding, and reception ceremony. Best decoration, best preparations, best time management, best execution by Team Eventena and Mr. Suraj Patil Sir. Thank you!",
    name: "Paras Chavan",
    title: "",
    rating: 5,
  },
  {
    quote:
      "Amazing arrangement by team Eventena. My 5 stars for their staff, service, and above all Suraj Patil. Everything was modernized and up to date. Keep going, will surely recommend more events.",
    name: "Ramaswamy Pillai",
    title: "",
    rating: 5,
  },
  {
    quote:
      "Nice decoration, proper planning, perfect execution. Thank you team Eventena and Mr. Suraj Patil sir.",
    name: "Vikas Sankapal",
    title: "",
    rating: 5,
  },
  {
    quote:
      "The event quality arranged by Eventena is consistently outstanding, exceeding my expectations every time. Wish you all the best, Eventena team!",
    name: "Abhishek Shriram",
    title: "",
    rating: 5,
  },
  {
    quote:
      "Recently I was invited by my friend to attend his wedding. As I visited there, I saw the grand decoration and asked his father about it. They told me about Eventena, and I really appreciate the team and the company for their decoration. It was amazing, and I loved it from my heart.",
    name: "Himanshu Madavi",
    title: "",
    rating: 5,
  },
  {
    quote:
      "Exceeded my expectations with their impeccable attention to detail and innovative design concepts.",
    name: "Pravin Rakh",
    title: "",
    rating: 5,
  },
  {
    quote:
      "Lovely setups, collections are very good. Thrilled to know that such a concept exists. Very best of luck ahead, Mr. Suraj Patil!",
    name: "Sangram Chavan",
    title: "",
    rating: 5,
  },
  {
    quote:
      "Eventena provides one of the most value-for-money event decoration and overall event planning and execution in Kolhapur. Highly recommended to get a quote from them before finalizing anywhere else.",
    name: "Aditya Achrekar",
    title: "",
    rating: 5,
  },
  {
    quote:
      "Best event management team ever met. The team is very supportive, and the arrangement done is satisfying. Our event went better than expected.",
    name: "Ruturaj Satpute",
    title: "",
    rating: 5,
  },
  {
    quote:
      "Five-star event management! Impeccable organization, creativity, and a dedicated team that ensures every detail is perfect. Our event was a huge success, thanks to their expertise and commitment to excellence. Would recommend without hesitation!",
    name: "Avinash Patil",
    title: "",
    rating: 5,
  },
  {
    quote:
      "Absolutely phenomenal event management! This company orchestrated our celebration flawlessly, demonstrating unmatched professionalism and creativity. Their ability to handle every aspect seamlessly made our event stress-free and unforgettable. A definite go-to for any special occasion!",
    name: "Sourabh Kanade",
    title: "",
    rating: 5,
  },
  {
    quote:
      "Great experience and good quality work. You all went above and beyond to make it a memorable experience for everyone who attended. Thanks, team Eventena!",
    name: "Pranit Ambulkar",
    title: "",
    rating: 5,
  },
  {
    quote:
      "Best event management team we ever met, best experience. Biggest wedding planner in town. Trust me, you will love it.",
    name: "Abhii",
    title: "",
    rating: 5,
  },
  {
    quote:
      "Outstanding event management. From start to finish, they exceeded our expectations. The team was incredibly organized, attentive to detail, and executed flawlessly. Thanks to their expertise and professionalism. Highly recommend!",
    name: "Dipraj Rathod",
    title: "",
    rating: 5,
  },
  {
    quote:
      "Highly recommended & appreciated. Great coordination and best quality work decor with amazing teamwork.",
    name: "Chinmay Jamsandekar",
    title: "",
    rating: 5,
  },
  {
    quote:
      "I highly recommend Eventena to anyone in search of a wedding planning and event management team. Their commitment to excellence and passion for creating unforgettable moments truly sets them apart. Thank you, Eventena, for making our wedding day a dream come true!",
    name: "Atharva Bhilare",
    title: "",
    rating: 5,
  },
  {
    quote:
      "Lovely setups, collections are very good. Thrilled to know that such a concept exists. Very best of luck ahead, Mr. Suraj Patil!",
    name: "Sangram Chavan",
    title: "",
    rating: 5,
  },
  {
    quote:
      "Nice decoration, proper planning, perfect execution. Thank you, team Eventena and Mr. Suraj Patil sir.",
    name: "Vikas Sankapal",
    title: "",
    rating: 5,
  },
  {
    quote:
      "Recently I was invited by my friend to attend his wedding. As I visited there, I saw the grand decoration and asked his father about it. They told me about Eventena, and I really appreciate the team and the company for their decoration. It was amazing, and I loved it from my heart.",
    name: "Himanshu Madavi",
    title: "",
    rating: 5,
  },
];
